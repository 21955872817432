// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "questionOptionsWrapper", "addQuestionButton", "removeQuestionButton" ]

  initialize() {
    if (document.querySelector("#event_form_question_last_order"))
      this.last_question_order = document.querySelector("#event_form_question_last_order").value || 0
    else
      this.last_question_order = 0
  }

  connect() {
    if (document.querySelector("#field-type-wrapper")){
      if (document.querySelector("#question-type-select").value == 'objective') {
        this.addQuestionButtonTarget.classList.remove("is-display-none")
        this.removeQuestionButtonTarget.classList.remove("is-display-none")
        document.querySelector('#field-type-wrapper').classList.add("is-display-none")
        document.querySelector('#mask-wrapper').classList.add("is-display-none")
      } else if (document.querySelector("#question-type-select").value == 'discursive') {
        this.addQuestionButtonTarget.classList.add("is-display-none")
        this.removeQuestionButtonTarget.classList.add("is-display-none")
        document.querySelector('#field-type-wrapper').classList.remove("is-display-none")
        document.querySelector('#mask-wrapper').classList.remove("is-display-none")
      } else {
        document.querySelector('#field-type-wrapper').classList.add("is-display-none")
        document.querySelector('#mask-wrapper').classList.add("is-display-none")
      }
    }
  }

  changeQuestionType(event) {
    let question_type = event.currentTarget.value
    if (question_type == 'discursive') {
      this.addQuestionButtonTarget.classList.add("is-display-none")
      this.removeQuestionButtonTarget.classList.add("is-display-none")
      document.querySelector('#field-type-wrapper').classList.remove("is-display-none")
      document.querySelector('#mask-wrapper').classList.remove("is-display-none")
    } else {
      this.addQuestionButtonTarget.classList.remove("is-display-none")
      this.removeQuestionButtonTarget.classList.remove("is-display-none")
      document.querySelector('#field-type-wrapper').classList.add("is-display-none")
      document.querySelector('#mask-wrapper').classList.add("is-display-none")
    }
  }

  addQuestionOption(event) {
    var divItem = document.createElement("div")
    divItem.classList.add("item")
    var divFlex2 = document.createElement("div")
    divFlex2.classList.add("flex")
    var formGroup2 = document.createElement("div")
    formGroup2.classList.add("flex-item")
    formGroup2.classList.add("form-group")
    var input2 = document.createElement("input")
    input2.type = "text"
    input2.placeholder = "Alternativa"
    input2.name = `event_form_question[event_form_question_options_attributes][${this.last_question_order}][option]`
    formGroup2.appendChild(input2)
    var formGroup3 = document.createElement("div")
    formGroup3.classList.add("flex-item")
    formGroup3.classList.add("form-group")
    var input3 = document.createElement("input")
    input3.name = `event_form_question[event_form_question_options_attributes][${this.last_question_order}][order]`
    input3.type = "number"
    input3.placeholder = "Ordem (Opcional)"
    formGroup3.appendChild(input3)
    divFlex2.appendChild(formGroup2)
    divFlex2.appendChild(formGroup3)
    divItem.appendChild(divFlex2)
    this.questionOptionsWrapperTarget.append(divItem)
    this.last_question_order++
  }

  removeQuestionOption(event) {
    const child = this.questionOptionsWrapperTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      this.questionOptionsWrapperTarget.lastElementChild.remove()
    }
  }

  removeQuestionOptionAlert(event) {
    if (event.currentTarget.checked)
      alert('Atenção! Os usuários que já tiverem respondido esta opção não poderão responder o seu formulário novamente. Respostas a opções removidas continuarão disponíveis no relatório de usuários.');
  }
}
