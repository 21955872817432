import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "formErrors", "loadingModal", "dataImportLoading" ]
  static values = { base64File: String }

  connect() {
    if (this.hasLoadingModalTarget && this.loadingModalTarget.dataset.processingId) {
      this.loadingModalTarget.style.display = "block"
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getDataImportCount(this.loadingModalTarget.dataset.processingId)
    }
  }

  processDataImports(event) {
    self = this
    this.formErrorsTarget.innerText = ""
    let addressFields = [
      { id: '#data_import_import_type', errorText: 'Tipo não pode ficar vazio' },
      { id: '#data_import_event_edition_id', errorText: 'Edição não pode ficar vazio' },
      { id: '#data_import_archive', errorText: 'Arquivo não pode ficar vazio' }
    ]
    addressFields.forEach(function(item){
      if (document.querySelector(item.id).value.trim().length == 0) {
        var error = item.errorText
        var li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }
    });

    if (this.formErrorsTarget.innerText != "") {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      event.currentTarget.disabled = true
      fetch('/importacoes', {
        method: 'POST',
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          data_import: {
            event_edition_id: document.querySelector('#data_import_event_edition_id').value,
            archive_base64: self.base64FileValue,
            archive_file_name: document.querySelector('#data_import_archive').files[0].name,
            import_type: document.querySelector('#data_import_import_type').value,
          }
        })
      })
      .then(function(response) {
        if (response.ok) {
          return response.json()
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          var error = 'Já existe uma importação em andamento, aguarde e tente novamente.'
          var li = document.createElement("li")
          li.appendChild(document.createTextNode(error))
          self.formErrorsTarget.appendChild(li)
        }
      })
      .then(function(r){
        if (r) {
          window.onbeforeunload = null
          window.location.reload(true)
        }
      })
    }
  }

  handleFileSelect(evt) {
    self = this

    let tgt = evt.target || window.event.srcElement
    let files = tgt.files
    if (FileReader && files && files.length) {
      var fr = new FileReader()
      fr.onload = function () {
        self.base64FileValue = fr.result
      }
      fr.readAsDataURL(files[0])
    }
  }

  getDataImportCount(data_import_id) {
    self = this
    fetch(`/importacoes/${data_import_id}`).then(
      function(response) {
        return response.json().then(function(r) {
          let percentage = ((r.data_import.imported_rows / r.data_import.total_rows) * 100).toFixed(2)
          document.querySelector("#data-import-progress progress").value = percentage
          document.querySelector("#data-import-progress #progress-bar").innerHTML = percentage + '%'

          self.dataImportLoadingTarget.innerHTML = ''
          if (r.data_import.import_results) {
            r.data_import.import_results.forEach(function(item){
              self.dataImportLoadingTarget.innerHTML += `<p class="${item.status}">${item.user.email || item.user.label}: ${item.message}</p>`
            })
          }

          if (r.data_import.total_rows > r.data_import.imported_rows) {
            setTimeout(function(){
              self.getDataImportCount(r.data_import.id) 
            }, 2000)
          } else {
            setTimeout(function(){
              alert('Processo de importação finalizado com sucesso')
              window.location.reload()
            }, 2000)
          }
        })
      }
    )
  }
}
