// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "kind", "descriptionBlock", "levelBlock", "socialMediaBlock", "lattesBlock" ]

  initialize() {
  }

  connect() {
    if (document.body.getAttribute("data-action").includes("new") || document.body.getAttribute("data-action").includes("edit")) {
      let kind = this.kindTarget.value
      switch (kind) {
        case "0":
          this.descriptionBlockTarget.classList.remove("is-display-none")
          this.socialMediaBlockTarget.classList.remove("is-display-none")
          this.lattesBlockTarget.classList.remove("is-display-none")
          break
        case "1":
          this.socialMediaBlockTarget.classList.remove("is-display-none")
          break
        case "2":
          this.socialMediaBlockTarget.classList.remove("is-display-none")
        case "3":
          this.descriptionBlockTarget.classList.remove("is-display-none")
          this.levelBlockTarget.classList.remove("is-display-none")
          this.socialMediaBlockTarget.classList.remove("is-display-none")
      }
    }
  }

  changeKind(event) {
    let kind = event.currentTarget.value
    this.descriptionBlockTarget.classList.add("is-display-none")
    this.levelBlockTarget.classList.add("is-display-none")
    switch (kind) {
      case "3":
        this.descriptionBlockTarget.classList.remove("is-display-none")
        this.levelBlockTarget.classList.remove("is-display-none")
        break
    }
  }
}
