// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "modalReAuthenticateAdmin" ]

  initialize() {    
  }

  connect() {
    
  }  

  openReAuthenticateAdminModal(event){    
    this.modalReAuthenticateAdminTarget.style.display = "block"
    this.modalSpeakerDeleteConfirmation = event.target.parentNode.nextElementSibling
  }

  reAuthenticateAdmin(event) {     
    this.modalReAuthenticateAdminTarget.style.display = "none"  
    this.modalSpeakerDeleteConfirmation.style.display = "block"    
  }

  startZipDownload(event) {
    var r = confirm(`Confirmar o download dos palestrantes?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/palestrantes/exportar.csv?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          name_or_email_or_bio_or_research_area_i_cont: document.querySelector("#q_name_or_email_or_bio_or_research_area_i_cont").disabled ? '' : document.querySelector("#q_name_or_email_or_bio_or_research_area_i_cont").value,
          event_editions_title_i_cont: document.querySelector("#q_event_editions_title_i_cont").disabled ? '' : document.querySelector("#q_event_editions_title_i_cont").value
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }
}