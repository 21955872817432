// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["articleEvaluationCriteriesWrapper", "addArticleEvaluationCriteryButton", "removeArticleEvaluationCriteryButton", "articleId", "eventEditionId", "EventManagerId", "saveEvaluationButton", "correctionsCommentsDiv", "requestCorrections", "correctionsComments"]

  initialize() {
    this.choiceConfig = {
      searchResultLimit: 1000,
      searchChoices: true,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando...",
      searchFloor: 3,
      renderChoiceLimit: 1
    }
  }

  connect() {
    if (document.body.getAttribute("data-action").includes("new")){
      if (this.hasAddArticleEvaluationCriteryButtonTarget){        
        let self = this
        setTimeout(function() {
          // document.querySelectorAll("[data-target='article-evaluations.addArticleEvaluationCriteryButton']")[0].click()
          self.addArticleEvaluationCriteryButtonTarget.click()  
        }, 1000);
      }      
    }  
    
    if (this.hasRequestCorrectionsTarget){
      this.changeRequestCorrections()
    }    
  }  

  async addArticleEvaluationCritery(event){
    event.target.classList.add("disabled")
    const evaluationCritery = await this.loadNextEvaluationCritery()
    if (evaluationCritery){
      const EVALUATION_CRITERY_ELIMINATORY = 0
      const EVALUATION_CRITERY_CLASSIFICATORY = 1
      var itemsCount = document.querySelectorAll('.evaluation-criteries').length
      var divItem = document.createElement("div")
      divItem.classList.add("item")
      divItem.classList.add('evaluation-criteries')
      
      //Name
      var divFlex = document.createElement("div")
      divFlex.classList.add("flex")
      
      var divFlexItem = document.createElement("div")
      divFlexItem.classList.add("flex-item")
      divFlexItem.classList.add("form-group")
      var evaluationCriteryNameLabel = document.createElement("label")
      evaluationCriteryNameLabel.innerHTML = "Critério"
      var evaluationCriteryNameSpan = document.createElement("span")
      evaluationCriteryNameSpan.innerHTML = evaluationCritery.description
      evaluationCriteryNameLabel.appendChild(evaluationCriteryNameSpan)
      var evaluationCriteryNameSelect = document.createElement("select") 
      evaluationCriteryNameSelect.disabled = false   
      evaluationCriteryNameSelect.classList.add("choice")
      evaluationCriteryNameSelect.setAttribute('data-action', 'change->application#changeSelect')
      evaluationCriteryNameSelect.setAttribute('data-target', 'article-evaluations.evaluationCriteryId')    
      evaluationCriteryNameSelect.name = "article_evaluation[article_evaluations_evaluation_criteries_attributes][" + itemsCount + "][evaluation_critery_id]"            
      var evaluationCriteryArticleId = document.createElement('option')    
      evaluationCriteryArticleId.value = evaluationCritery.id
      evaluationCriteryArticleId.innerHTML = evaluationCritery.name       
      evaluationCriteryNameSelect.appendChild(evaluationCriteryArticleId)    

      divFlexItem.appendChild(evaluationCriteryNameLabel)
      divFlexItem.appendChild(evaluationCriteryNameSelect)

      //Score
      var divFlex2 = document.createElement("div")
      divFlex2.classList.add("flex")

      var divFlexItem2 = document.createElement("div")
      divFlexItem2.classList.add("flex-item")
      divFlexItem2.classList.add("form-group")

      var hiddenEvaluationCriteryEvaluationType = document.createElement("input")
      hiddenEvaluationCriteryEvaluationType.setAttribute("type", "hidden")
      hiddenEvaluationCriteryEvaluationType.setAttribute("name", "article_evaluation[article_evaluations_evaluation_criteries_attributes][" + itemsCount + "][evaluation_critery_evaluation_type]")
      hiddenEvaluationCriteryEvaluationType.setAttribute("value", evaluationCritery.evaluation_type)
      divFlexItem2.appendChild(hiddenEvaluationCriteryEvaluationType)

      var evaluationCriteryScoreLabel = document.createElement("label")
      evaluationCriteryScoreLabel.innerHTML = "Nota"
      var evaluationCriteryScoreSpan = document.createElement("span")      
      evaluationCriteryScoreLabel.appendChild(evaluationCriteryScoreSpan)
      divFlexItem2.appendChild(evaluationCriteryScoreLabel)
      if (evaluationCritery.evaluation_type == EVALUATION_CRITERY_ELIMINATORY){
        var evaluationCriteryScoreSelect = document.createElement("select")         
        evaluationCriteryScoreSelect.classList.add("choice")
        evaluationCriteryScoreSelect.setAttribute('data-action', 'change->application#changeSelect')
        evaluationCriteryScoreSelect.setAttribute('data-action', 'change->article-evaluations#showOrHideAddArticleEvaluationCriteryButton')
        evaluationCriteryScoreSelect.setAttribute('data-target', 'article-evaluations.articleEvaluationsEvaluationCriteriesScore')
        evaluationCriteryScoreSelect.name = "article_evaluation[article_evaluations_evaluation_criteries_attributes][" + itemsCount + "][score]"
        var evaluationCriteryArticleScoreBlank = document.createElement('option')
        evaluationCriteryArticleScoreBlank.innerHTML = ""
        var evaluationCriteryArticleScoreApproved = document.createElement('option')
        evaluationCriteryArticleScoreApproved.value = 10.0
        evaluationCriteryArticleScoreApproved.innerHTML = "Aprovado"
        var evaluationCriteryArticleScoreDisapproved = document.createElement('option')    
        evaluationCriteryArticleScoreDisapproved.value = 0.0
        evaluationCriteryArticleScoreDisapproved.innerHTML = "Reprovado"
        evaluationCriteryScoreSelect.appendChild(evaluationCriteryArticleScoreBlank)
        evaluationCriteryScoreSelect.appendChild(evaluationCriteryArticleScoreApproved)
        evaluationCriteryScoreSelect.appendChild(evaluationCriteryArticleScoreDisapproved)
        divFlexItem2.appendChild(evaluationCriteryScoreSelect)
      } else {
        var evaluationCriteryScoreText = document.createElement("input")
        evaluationCriteryScoreText.setAttribute('data-action', 'keyup->application#onlyNumbersWithOneDecimalPlace')
        evaluationCriteryScoreText.type = "number"
        evaluationCriteryScoreText.step = 0.1
        evaluationCriteryScoreText.min = 0.0
        evaluationCriteryScoreText.max = 10.0
        evaluationCriteryScoreText.name = "article_evaluation[article_evaluations_evaluation_criteries_attributes][" + itemsCount + "][score]"
        divFlexItem2.appendChild(evaluationCriteryScoreText)
      }

      divFlex.appendChild(divFlexItem)
      divFlex2.appendChild(divFlexItem2)
      divItem.appendChild(divFlex)
      divItem.appendChild(divFlex2)

      var hr = document.createElement("hr")
      divItem.appendChild(hr)

      this.articleEvaluationCriteriesWrapperTarget.append(divItem)
      event.target.classList.remove("disabled")

      // this.removeArticleEvaluationCriteryButtonTarget.classList.remove("is-display-none")  
    } else {
      alert("Todos os critérios disponíveis para avaliação deste artigo já foram listados!")
      // this.addArticleEvaluationCriteryButtonTarget.classList.add("is-display-none")
      event.target.classList.remove("disabled")
    }

  }

  async loadNextEvaluationCritery(){
    let evaluationCriteries = []
    document.querySelectorAll("[data-target='article-evaluations.evaluationCriteryId']").forEach(e => {
        if (!(e.value == "") || (e.value == undefined)) {
          evaluationCriteries.push(e.value)
        }
    })
    
    return fetch("/resumos/" + this.articleIdTarget.value + "/criterios-de-avaliacao/proximo-criterio?event_edition_id=" + this.eventEditionIdTarget.value + "&except_ids=" + evaluationCriteries)     
          .then((response) => response.json())
          .then((responseJson) => {return responseJson})
  }

  // showOrHideAddArticleEvaluationCriteryButton(){
  //   const DISAPPROVEDSCORE = 0
  //   let evaluationScores = []
  //   document.querySelectorAll("[data-target='article-evaluations.articleEvaluationsEvaluationCriteriesScore']").forEach(e => {
  //     if (!(e.value == "") || (e.value == undefined)) {
  //       evaluationScores.push(parseInt(e.value))
  //     }
  //   })    
  //   if (evaluationScores.includes(DISAPPROVEDSCORE)){
  //     this.addArticleEvaluationCriteryButtonTarget.classList.add("is-display-none")
  //   } else {
  //     this.addArticleEvaluationCriteryButtonTarget.classList.remove("is-display-none")
  //   }
  // }

  showOrHideAddArticleEvaluationCriteryButton(element){
    const DISAPPROVEDSCORE = 0
    if (parseInt(element.currentTarget.value) == DISAPPROVEDSCORE){
      this.addArticleEvaluationCriteryButtonTarget.classList.add("is-display-none")
      do
        element.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.remove()
      while (element.currentTarget.parentElement.parentElement.parentElement.nextElementSibling)      
    } else {
      this.addArticleEvaluationCriteryButtonTarget.classList.remove("is-display-none")
    }
  }  

  changeCommitButtonValue(el){
    if (el.currentTarget.checked){
      this.saveEvaluationButtonTarget.value = "Salvar Rascunho"
    } else {
      this.saveEvaluationButtonTarget.value = "Enviar"
    }
  }

  changeRequestCorrections(){
    if (this.requestCorrectionsTarget.checked){
      this.correctionsCommentsDivTarget.classList.remove("is-display-none")
      this.loadCKEditor()
    } else {
      this.correctionsCommentsDivTarget.classList.add("is-display-none")
    }
  }

  loadCKEditor() {    
    let toolbarConfig = [
      { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
      { name: 'styles', items: [ 'Font', 'FontSize' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'CopyFormatting' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' ] },
      { name: 'insert', items: ['base64image', 'Table'] },
      { name: 'editing', items: [ 'Scayt' ] }
    ]

    let config = {
      customConfig: '/js/ckeditor_config.js',
      toolbar: toolbarConfig,
      wordcount: {
        showWordCount: true,
        showCharCount: false
      }
    }

    if (CKEDITOR.instances.hasOwnProperty("article_evaluation_corrections_comments")) {
      CKEDITOR.instances.article_evaluation_corrections_comments.destroy()
    }
    CKEDITOR.replace(document.querySelector('#article_evaluation_corrections_comments'), config)    
  }
}
