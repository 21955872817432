// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"
import IMask from "imask"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

export default class extends Controller {

  static targets = ["speakers", "presentationsWrapper", "addEventEditionButton", "removeEventEditionButton", "deleteEventEditionCheckBox", "youtubeVideoUrl", "kind", "deliveryType", "roomUrl", "source", "liveChannelNameDiv", "liveOptionsDiv", "liveChannelName", "videoUrl", "roomUrlDiv", "youtubeVideoUrlSpan"]

  initialize() {
    this.flatpickrConfig = {
      enableTime: true,
      altInput: true,
      altFormat: "j/m/Y H:i:s",
      locale: Portuguese,
      enableSeconds: true
    }

    this.choiceConfig = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }

    this.choiceConfigWithoutRemoveItem = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom",
      removeItems: false,
      removeItemButton: false,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }

    if (document.querySelector('#presentation_speaker_ids'))
      this.choiceSpeakerIds = new Choices(document.querySelector('#presentation_speaker_ids'), this.choiceConfig)

    if (document.querySelector('#presentation_product_ids'))
      this.choiceProductIds = new Choices(document.querySelector('#presentation_product_ids'), this.choiceConfig)

    if (document.querySelector('#presentation_delivery_type'))
      this.deliveryType = new Choices(document.querySelector('#presentation_delivery_type'), this.choiceConfig)

    if (document.querySelector('#presentation_kind'))
      this.presentationKind = new Choices(document.querySelector('#presentation_kind'), this.choiceConfig)

    if (document.querySelector('#q_custom_materials_status')) {
      let status = new URLSearchParams(decodeURI(document.location.search)).getAll("q[custom_materials_status][]")
      let materialStatusSelect = document.querySelector("#q_custom_materials_status")
      materialStatusSelect.choices = new Choices(materialStatusSelect, {
        position: "bottom",
        removeItems: true,
        removeItemButton: true,
        searchResultLimit: 15,
        sorter: function() { return },
        noChoicesText: 'Nenhum item para ser selecionado',
        itemSelectText: "Pressione enter para adicionar",
        fuseOptions: {
          threshold: 0.2,
          matchAllTokens: true,
          tokenize: true
        }
      })
      if (!status.length) {
        materialStatusSelect.choices.disable()
      }
    }

    if (document.querySelector('#presentation_source'))
      this.presentationSource = new Choices(document.querySelector('#presentation_source'), this.choiceConfig)

    this.eventEditionsSections = {}
    this.eventEditionsSubSections = {}
  }

  connect() {
    self = this
    if ((document.body.getAttribute("data-action").includes("new")) || (document.body.getAttribute("data-action").includes("edit"))) {
      if (document.body.getAttribute("data-action").includes("new")) {
        if (document.querySelector("div[data-target='presentations.presentationsWrapper']")) {
          this.addEventEditionPresentation()
        }
      }
      flatpickr(".datetime-select-input", this.flatpickrConfig)
      this.changeDeliveryType()
      this.changeSource()
    }
    if (document.body.getAttribute("data-action").includes("edit")) {
      this.changeEventEdition()
      this.changePresentationKind(null, this.kindTarget)
      document.querySelectorAll("[data-target='presentations.presentationAccessType']").forEach(function(e) {
        self.hideOrShowDates(e)
      })
      if (document.querySelector("div[data-target='presentations.presentationsWrapper']")) {
        this.removeEventEditionButtonTarget.classList.add("is-display-none")
      }
    }
  }

  loadEventEditions(targetElementChoice) {
    let eventEditions = []
    document.querySelectorAll("[data-target='presentations.eventEditionId']").forEach(e => {
      if (!(e.value == "") || (e.value == undefined)) {
        eventEditions.push(e.value)
      }
    })
    targetElementChoice.setChoices(function(callback) {
      return fetch(
          "/edicoes-de-evento/listar-todas?except_ids=" + eventEditions
        )
        .then(function(response) {
          return response.json()
        })
        .then(function(data) {
          return [{ value: '', label: '' }].concat(data.map(function(eventEdition) {
            return { value: eventEdition.id, label: eventEdition.title }
          }))
        })
    })
  }

  changeEventEdition(event) {
    self = this
    this.choiceSpeakerIds.clearChoices()
    this.choiceProductIds.clearChoices()
    let eventEditions = []

    if (event) {
      let acessTypeTarget = event.currentTarget.closest(".item").querySelector("[data-target='presentations.presentationAccessType']")
      self.hideOrShowDates(acessTypeTarget)
    }

    document.querySelectorAll("[data-target='presentations.eventEditionId']").forEach(e => {
      if (!(e.value == "") || (e.value == undefined)) {
        eventEditions.push(e.value)
      }
    })
    if (eventEditions.length > 0) {
      self.choiceSpeakerIds.setChoices(function(callback) {
        return fetch(
            "/filtrar-palestrantes/" + eventEditions
          )
          .then(function(response) {
            return response.json()
          })
          .then(function(data) {
            return [{ value: '', label: '' }].concat(data.map(function(speaker) {
              return { value: speaker.id, label: speaker.name }
            }))
          })
      })

      self.choiceProductIds.setChoices(function(callback) {
        return fetch(
            "/filtrar-produtos/" + eventEditions
          )
          .then(function(response) {
            return response.json()
          })
          .then(function(data) {
            return [{ value: '', label: '' }].concat(data.map(function(product) {
              return { value: product.id, label: product.name }
            }))
          })
      })
    }
  }

  hideOrShowDates(targetElement) {
    this.changeAccessType(targetElement)
  }

  changeAccessType(event) {
    let accessTypeTarget = event.currentTarget ? event.currentTarget : event
    let gridTypeTarget = accessTypeTarget.closest(".item").querySelector("select[data-target='presentations.presentationGridType']")

    this.hideOrShowGridTypeOptions(gridTypeTarget, accessTypeTarget)
  }

  changePresentationKind(event, currentTarget) {
    let current = null
    if (event) {
      current = event.currentTarget
    } else {
      current = currentTarget
    }
    // if (current.value == "1") {
    //   this.speakersTarget.classList.add("is-display-none")
    // } else {
    //   this.speakersTarget.classList.remove("is-display-none")
    // }
    if (current.value == "5") {
      this.deliveryType.setChoiceByValue("1")
    } else {
      //this.youtubeVideoUrlTarget.classList.remove("is-display-none")
    }
  }

  changeGridType(event) {
    let gridTypeTarget = event.currentTarget.closest(".item").querySelector("select[data-target='presentations.presentationGridType']")
    let accessTypeTarget = event.currentTarget.closest(".item").querySelector("select[data-target='presentations.presentationAccessType']")
    this.hideOrShowGridTypeOptions(gridTypeTarget, accessTypeTarget)
  }

  hideOrShowGridTypeOptions(gridTypeTarget, accessTypeTarget) {
    let endDateTarget = accessTypeTarget.closest(".item").querySelector("div[data-target='presentations.endDate']")
    let eventEditionSubSectionDivTarget = accessTypeTarget.closest(".item").querySelector("div[data-target='presentations.eventEditionsSubSectionDiv']")
    let eventEditionsSectionTarget = accessTypeTarget.closest(".item").querySelector("[data-target='presentations.eventEditionsSection']")
    let accessType = accessTypeTarget.value
    let gridType = gridTypeTarget.value
    this.populateSections(eventEditionsSectionTarget)

    if (accessType == "1") {
      endDateTarget.classList.add("is-display-none")
      endDateTarget.querySelectorAll("input.input, input.flatpickr-input").forEach(function(input) {
        input.value = null
      })
      if (gridType == "with_custom_separator") {
        eventEditionSubSectionDivTarget.classList.remove("is-display-none")
      } else {
        eventEditionSubSectionDivTarget.classList.add("is-display-none")
      }
    } else {
      endDateTarget.classList.remove("is-display-none")
      if (gridType == "with_custom_separator") {
        eventEditionSubSectionDivTarget.classList.remove("is-display-none")
      } else {
        eventEditionSubSectionDivTarget.classList.add("is-display-none")
      }
    }
  }

  populateSections(target, reinitialize) {
    self = this
    let eventEditionsSubSectionTarget = target.closest(".item").querySelector("[data-target='presentations.eventEditionsSubSection']")
    let event_edition_id = target.closest(".item").querySelector("[data-target='presentations.eventEditionId']").value
    let grid_type = target.closest(".item").querySelector("[data-target='presentations.presentationGridType']").value

    if (this.eventEditionsSections[target.id])
      this.eventEditionsSections[target.id].destroy()
    target.innerHTML = ""

    var option = document.createElement("option")
    if (grid_type == "with_date_separator")
      option.appendChild(document.createTextNode("Programação (Padrão)"))
    else if (grid_type == "with_custom_separator")
      option.appendChild(document.createTextNode("Selecione"))
    else if (grid_type == "without_separator")
      option.appendChild(document.createTextNode("Programação Premium (Padrão)"))
    option.value = ""
    target.appendChild(option)

    if (reinitialize) {
      self.eventEditionsSections[target.id] = new Choices(target, self.choiceConfigWithoutRemoveItem)
      delete target.dataset.selectedId
      delete eventEditionsSubSectionTarget.dataset.selectedId
    } else {
      if (event_edition_id && grid_type) {
        fetch(`/edicoes-de-evento/${event_edition_id}/secoes-de-conteudos?grid_type=${grid_type}`).then(
          function(response) {
            return response.json().then(function(json) {
              for (var i = 0; i < json.length; i++) {
                var option = document.createElement("option")
                option.appendChild(document.createTextNode(json[i]["grid_name"]))
                option.value = json[i]["id"]
                if (json[i]["id"] == target.dataset.selectedId)
                  option.selected = true
                target.appendChild(option)
              }
              self.eventEditionsSections[target.id] = new Choices(target, self.choiceConfigWithoutRemoveItem)
              target.dispatchEvent(new Event('change'))
            })
          }
        )
      } else {
        self.eventEditionsSections[target.id] = new Choices(target, self.choiceConfigWithoutRemoveItem)
      }
    }
  }

  changeEventEditionsSection(event) {
    let eventEditionsSubSectionTarget = event.currentTarget.closest(".item").querySelector("[data-target='presentations.eventEditionsSubSection']")
    let selectedValue = event.currentTarget.options[event.currentTarget.selectedIndex].value

    this.populateSubSections(eventEditionsSubSectionTarget, selectedValue)
  }

  populateSubSections(target, section_id) {
    self = this
    if (this.eventEditionsSubSections[target.id])
      this.eventEditionsSubSections[target.id].destroy()
    target.innerHTML = ""

    var option = document.createElement("option")
    option.appendChild(document.createTextNode("Selecione"))
    option.value = ""
    target.appendChild(option)

    if (section_id) {
      fetch(`/secoes-de-conteudos/${section_id}/sub-secoes-de-conteudos`).then(
        function(response) {
          return response.json().then(function(json) {
            for (var i = 0; i < json.length; i++) {
              var option = document.createElement("option")
              option.appendChild(document.createTextNode(json[i]["name"]))
              option.value = json[i]["id"]
              if (json[i]["id"] == target.dataset.selectedId)
                option.selected = true
              target.appendChild(option)
            }
            self.eventEditionsSubSections[target.id] = new Choices(target, self.choiceConfigWithoutRemoveItem)
          })
        }
      )
    } else {
      self.eventEditionsSubSections[target.id] = new Choices(target, self.choiceConfigWithoutRemoveItem)
    }
  }

  changeDeliveryType() {
    if (this.deliveryTypeTarget.value == "0") {
      // this.youtubeVideoUrlTarget.classList.remove("is-display-none")
      this.liveOptionsDivTarget.classList.add("is-display-none")
      this.presentationSource.setChoiceByValue("other_sources")
      this.roomUrlDivTarget.classList.add('is-display-none')
      this.youtubeVideoUrlSpanTarget.innerHTML = "Use se quiser transmitir um vídeo do YouTube apenas durante o período de gratuidade (findo o prazo, passa a valer a URL padrão). Ex.: (https://www.youtube.com/embed/Ap7mI2LzgKU)."
      this.changeSource()
    } else {
      // this.youtubeVideoUrlTarget.classList.add("is-display-none")
      this.liveOptionsDivTarget.classList.remove("is-display-none")
      this.roomUrlDivTarget.classList.remove('is-display-none')
      this.youtubeVideoUrlSpanTarget.innerHTML = "Use se quiser que os controles e referências do canal fiquem ocultos. Exclusivo para YouTube. Ex.: (https://www.youtube.com/embed/Ap7mI2LzgKU)."
        //this.presentationKind.setChoiceByValue("5") 
    }
  }

  addEventEditionPresentation(event) {
    var itemsCount = document.querySelectorAll('.item').length
    var divItem = document.createElement("div")
    divItem.classList.add("item")

    var divFlex = document.createElement("div")
    divFlex.classList.add("flex")

    //Event Edition
    var divFlexItem = document.createElement("div")
    divFlexItem.classList.add("flex-item")
    divFlexItem.classList.add("form-group")
    var eventEditionLabel = document.createElement("label")
    eventEditionLabel.innerHTML = "Edição"
    var eventEditionSpan = document.createElement("span")
    eventEditionSpan.innerHTML = "Edição a qual este conteúdo pertencerá"
    eventEditionLabel.appendChild(eventEditionSpan)
    var eventEditionSelect = document.createElement("select")
    eventEditionSelect.setAttribute('data-action', 'change->application#changeSelect change->presentations#changeEventEdition')
    eventEditionSelect.setAttribute('data-target', 'presentations.eventEditionId')
    eventEditionSelect.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][event_edition_id]"
    divFlexItem.appendChild(eventEditionLabel)
    divFlexItem.appendChild(eventEditionSelect)
    this.loadEventEditions(new Choices(eventEditionSelect, this.choiceConfigWithoutRemoveItem))

    //Grid Type
    var divFlexItem1 = document.createElement("div")
    divFlexItem1.classList.add("flex-item")
    divFlexItem1.classList.add("form-group")
    var gridTypeLabel = document.createElement("label")
    gridTypeLabel.innerHTML = "Tipo de Grade"
    var gridTypeSpan = document.createElement("span")
    gridTypeSpan.innerHTML = "Qual o tipo de grade de programação na qual você deseja exibir este conteúdo?"
    gridTypeLabel.appendChild(gridTypeSpan)
    var gridTypeSelect = document.createElement("select")
    gridTypeSelect.setAttribute('data-action', 'change->application#changeSelect change->presentations#changeGridType')
    gridTypeSelect.setAttribute('data-target', 'presentations.presentationGridType')
    gridTypeSelect.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][grid_type]"
    var gridTypeBlank = document.createElement('option')
    gridTypeBlank.innerHTML = ""
    var gridType1 = document.createElement('option')
    gridType1.value = "with_date_separator"
    gridType1.innerHTML = "Com subdivisões de data"
    var gridType2 = document.createElement('option')
    gridType2.value = "with_custom_separator"
    gridType2.innerHTML = "Com subdivisões personalizadas"
    var gridType3 = document.createElement('option')
    gridType3.value = "without_separator"
    gridType3.innerHTML = "Sem subdivisões"
    gridTypeSelect.appendChild(gridTypeBlank)
    gridTypeSelect.appendChild(gridType1)
    gridTypeSelect.appendChild(gridType2)
    gridTypeSelect.appendChild(gridType3)
    divFlexItem1.appendChild(gridTypeLabel)
    divFlexItem1.appendChild(gridTypeSelect)
    new Choices(gridTypeSelect, this.choiceConfigWithoutRemoveItem)

    var divFlex1 = document.createElement("div")
    divFlex1.classList.add("flex")

    //Access Type
    var divFlexItem2 = document.createElement("div")
    divFlexItem2.classList.add("flex-item")
    divFlexItem2.classList.add("form-group")
    var accessTypeLabel = document.createElement("label")
    accessTypeLabel.innerHTML = "Acesso gratuito"
    var accessTypeSpan = document.createElement("span")
    accessTypeSpan.innerHTML = "Este conteúdo será exibido gratuitamente?"
    accessTypeLabel.appendChild(accessTypeSpan)
    var accessTypeSelect = document.createElement("select")
    accessTypeSelect.classList.add("choice")
    accessTypeSelect.setAttribute('data-action', 'change->application#changeSelect change->presentations#changeAccessType')
    accessTypeSelect.setAttribute('data-target', 'presentations.presentationAccessType')
    accessTypeSelect.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][presentation_access_type]"
    var accessTypeBlank = document.createElement('option')
    accessTypeBlank.innerHTML = ""
    var accessTypeYes = document.createElement('option')
    accessTypeYes.value = 0
    accessTypeYes.innerHTML = "Sim"
    var accessTypeNo = document.createElement('option')
    accessTypeNo.value = 1
    accessTypeNo.innerHTML = "Não"
    accessTypeSelect.appendChild(accessTypeBlank)
    accessTypeSelect.appendChild(accessTypeYes)
    accessTypeSelect.appendChild(accessTypeNo)
    divFlexItem2.appendChild(accessTypeLabel)
    divFlexItem2.appendChild(accessTypeSelect)
    new Choices(accessTypeSelect, this.choiceConfigWithoutRemoveItem)

    //Event Edition Section
    var divFlexItem3 = document.createElement("div")
    divFlexItem3.classList.add("flex-item")
    divFlexItem3.classList.add("form-group")
    var eventEditionsSectionLabel = document.createElement("label")
    eventEditionsSectionLabel.innerHTML = "Seção de Programação"
    var eventEditionsSectionSpan = document.createElement("span")
    eventEditionsSectionSpan.innerHTML = "Em qual seção este conteúdo deve ser exibido?"
    eventEditionsSectionLabel.appendChild(eventEditionsSectionSpan)
    var eventEditionSectionSelect = document.createElement("select")
    eventEditionSectionSelect.classList.add("choice")
    eventEditionSectionSelect.setAttribute('data-action', 'change->application#changeSelect change->presentations#changeEventEditionsSection')
    eventEditionSectionSelect.setAttribute('data-target', 'presentations.eventEditionsSection')
    eventEditionSectionSelect.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][event_editions_section_id]"
    eventEditionSectionSelect.id = "presentation_event_editions_presentations_attributes_" + itemsCount + "_event_editions_section_id"
    var accessTypeBlank = document.createElement('option')
    divFlexItem3.appendChild(eventEditionsSectionLabel)
    divFlexItem3.appendChild(eventEditionSectionSelect)
 
    //Dates
    var divFlex2 = document.createElement("div")
    divFlex2.classList.add("flex")

    // Button Text
    var divFlexItem11 = document.createElement("div");
    divFlexItem11.classList.add("flex-item");
    divFlexItem11.classList.add("form-group");
    var buttonTextLabel = document.createElement("label");
    buttonTextLabel.innerHTML = "Texto para botão de acesso ao conteúdo";
    var buttonTextSpan = document.createElement("span");
    buttonTextSpan.innerHTML = "Este campo registra o texto exibido no botão que dá acesso ao conteúdo da apresentação";
    buttonTextLabel.appendChild(buttonTextSpan);
    var buttonTextInput = document.createElement("input");
    buttonTextInput.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][button_text]";
    buttonTextInput.type = "text";
    divFlexItem11.appendChild(buttonTextLabel);
    divFlexItem11.appendChild(buttonTextInput);
    
    // Button Start Time
    var divFlexItem12 = document.createElement("div");
    divFlexItem12.classList.add("flex-item");
    divFlexItem12.classList.add("form-group");
    var buttonStartTimeLabel = document.createElement("label");
    buttonStartTimeLabel.innerHTML = "Data e hora para exibição do botão";
    var buttonStartTimeSpan = document.createElement("span");
    buttonStartTimeSpan.innerHTML = "Este campo registra o momento em que o botão que concede acesso ao conteúdo será exibido";
    buttonStartTimeLabel.appendChild(buttonStartTimeSpan);
    var buttonStartTimeInput = document.createElement("input");
    buttonStartTimeInput.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][button_start_time]";
    buttonStartTimeInput.type = "text";
    buttonStartTimeInput.classList.add("datetime-select-input");
    divFlexItem12.appendChild(buttonStartTimeLabel);
    divFlexItem12.appendChild(buttonStartTimeInput);
    
    //Start At
    var divFlexItem4 = document.createElement("div")
    divFlexItem4.classList.add("flex-item")
    divFlexItem4.classList.add("form-group")
    var divDateTimeSelect1 = document.createElement("div")
    divDateTimeSelect1.classList.add("flex")
    divDateTimeSelect1.classList.add("ai-center")
    divDateTimeSelect1.classList.add("jc-between")
    divDateTimeSelect1.classList.add("is-no-column")
    divDateTimeSelect1.classList.add("datetime-select")
    var startAtLabel = document.createElement("label")
    startAtLabel.innerHTML = "Início da exibição do conteúdo"
    var startAtSpan = document.createElement("span")
    startAtSpan.innerHTML = "Caso não seja especificada, a data de início será definida como a data da edição"
    startAtLabel.appendChild(startAtSpan)
    var startAtInput = document.createElement("input")
    startAtInput.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][presentation_start_at]"
    startAtInput.type = "text"
    divDateTimeSelect1.appendChild(startAtInput)
    flatpickr(startAtInput, this.flatpickrConfig)
    divFlexItem4.appendChild(startAtLabel)
    divFlexItem4.appendChild(divDateTimeSelect1)

    //End At
    var divFlexItem5 = document.createElement("div")
    divFlexItem5.classList.add("flex-item")
    divFlexItem5.classList.add("form-group")
    var divTargetEndDate = document.createElement("div")
    divTargetEndDate.dataset.target = "presentations.endDate"
    var divDateTimeSelect2 = document.createElement("div")
    divDateTimeSelect2.classList.add("flex")
    divDateTimeSelect2.classList.add("ai-center")
    divDateTimeSelect2.classList.add("jc-between")
    divDateTimeSelect2.classList.add("is-no-column")
    divDateTimeSelect2.classList.add("datetime-select")
    var endAtLabel = document.createElement("label")
    endAtLabel.innerHTML = "Fim da gratuidade do conteúdo"
    var endAtSpan = document.createElement("span")
    endAtSpan.innerHTML = "Data limite estabelecida para o término da gratuidade do conteúdo"
    endAtLabel.appendChild(endAtSpan)
    var endAtInput = document.createElement("input")
    endAtInput.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][presentation_end_at]"
    endAtInput.type = "text"
    divDateTimeSelect2.appendChild(endAtInput)
    flatpickr(endAtInput, this.flatpickrConfig)
    divTargetEndDate.appendChild(endAtLabel)
    divTargetEndDate.appendChild(divDateTimeSelect2)
    divFlexItem5.appendChild(divTargetEndDate)

    var divFlex3 = document.createElement("div")
    divFlex3.classList.add("flex")

    //Event Edition Sub Section
    var divFlexItem6 = document.createElement("div")
    divFlexItem6.classList.add("flex-item")
    divFlexItem6.classList.add("form-group")
    divFlexItem6.setAttribute('data-target', 'presentations.eventEditionsSubSectionDiv')
    var eventEditionsSubSectionLabel = document.createElement("label")
    eventEditionsSubSectionLabel.innerHTML = "Subdivisão de Programação"
    var eventEditionsSubSectionSpan = document.createElement("span")
    eventEditionsSubSectionSpan.innerHTML = "Em qual subdivisão este conteúdo deve ser exibido?"
    eventEditionsSubSectionLabel.appendChild(eventEditionsSubSectionSpan)
    var eventEditionSubSectionSelect = document.createElement("select")
    eventEditionSubSectionSelect.classList.add("choice")
    eventEditionSubSectionSelect.setAttribute('data-action', 'change->application#changeSelect')
    eventEditionSubSectionSelect.setAttribute('data-target', 'presentations.eventEditionsSubSection')
    eventEditionSubSectionSelect.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][event_editions_sub_section_id]"
    eventEditionSubSectionSelect.id = "presentation_event_editions_presentations_attributes_" + itemsCount + "_event_editions_sub_section_id"
    divFlexItem6.appendChild(eventEditionsSubSectionLabel)
    divFlexItem6.appendChild(eventEditionSubSectionSelect)

    // Images
    var divFlexItem7 = document.createElement("div")
    divFlexItem7.classList.add("flex-item")
    divFlexItem7.classList.add("form-group")
    divFlexItem7.classList.add("file")
    var divPresentationPictureLabel = document.createElement("label")
    divPresentationPictureLabel.innerHTML = "Imagens"
    var presentationPictureSpan = document.createElement("span")
    presentationPictureSpan.innerHTML = "Imagem que será exibida ao lado do conteúdo na programação. Se ficar em branco, a foto será a do palestrante."
    divPresentationPictureLabel.appendChild(presentationPictureSpan)
    var divPresentationPictureInput = document.createElement("input")
    divPresentationPictureInput.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][presentation_picture]"
    divPresentationPictureInput.setAttribute('data-action', 'change->application#changeImage')
    divPresentationPictureInput.type = "file"
    var divFileItem = document.createElement("div")
    divFileItem.classList.add("flex")
    divFileItem.classList.add("col")
    divFileItem.classList.add("ai-start")
    var divFileImg = document.createElement("img")
    divFileImg.classList.add("picture")
    divFileImg.classList.add("presentation-picture")
    divFileImg.classList.add("is-display-none")
    var divFileCenter = document.createElement("div")
    divFileCenter.classList.add("flex")
    divFileCenter.classList.add("jc-center")
    divFileCenter.classList.add("ai-center")
    var divFileCenterI = document.createElement("i")
    divFileCenterI.classList.add("icon")
    divFileCenterI.classList.add("icon-image")
    divFileCenter.appendChild(divFileCenterI)
    var divFileA = document.createElement("a")
    divFileA.classList.add("btn")
    divFileA.classList.add("indigo-dye")
    divFileA.classList.add("outline")
    divFileA.setAttribute('data-action', 'click->application#triggeredInput click->application#preventDefault')
    divFileA.setAttribute('href', '#')
    divFileA.innerHTML = "Adicionar Imagem"
    divFileItem.appendChild(divFileImg)
    divFileItem.appendChild(divFileCenter)
    divFileItem.appendChild(divFileA)
    divFlexItem7.appendChild(divPresentationPictureLabel)
    divFlexItem7.appendChild(divPresentationPictureInput)
    divFlexItem7.appendChild(divFileItem)

    var divFlex4 = document.createElement("div")
    divFlex4.classList.add("flex")

    var divFlexItem9 = document.createElement("div")
    divFlexItem9.classList.add("flex-item")
    divFlexItem9.classList.add("form-group")
    var maximumRegistationsLabel = document.createElement("label")
    maximumRegistationsLabel.innerHTML = "Máximo de vagas"
    var maximumRegistationsSpan = document.createElement("span")
    maximumRegistationsSpan.innerHTML = "Caso o conteúdo tenha limite de inscritos, indique aqui"
    maximumRegistationsLabel.appendChild(maximumRegistationsSpan)
    var maximumRegistationsInput = document.createElement("input")
    maximumRegistationsInput.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][maximum_registrations]"
    maximumRegistationsInput.type = "text"
    divFlexItem9.appendChild(maximumRegistationsLabel)
    divFlexItem9.appendChild(maximumRegistationsInput)
    IMask(maximumRegistationsInput, { mask: Number })

    var divFlexItem10 = document.createElement("div")
    divFlexItem10.classList.add("flex-item")
    divFlexItem10.classList.add("form-group")

    var divFlex5 = document.createElement("div")
    divFlex5.classList.add("flex")

    var divFlexItem8 = document.createElement("div")
    divFlexItem8.classList.add("flex-item")
    divFlexItem8.classList.add("form-group")
    var complementaryMaterialLabel = document.createElement("label")
    complementaryMaterialLabel.innerHTML = "Material complementar"
    var complementaryMaterialSpan = document.createElement("span")
    complementaryMaterialSpan.innerHTML = "Material complementar do conteúdo"
    complementaryMaterialLabel.appendChild(complementaryMaterialSpan)
    var eventEditionSubSectionTextArea = document.createElement("trix-editor")
    eventEditionSubSectionTextArea.id = "presentation_event_editions_presentations_attributes_" + itemsCount + "_complementary_material"
    divFlexItem8.appendChild(complementaryMaterialLabel)
    divFlexItem8.appendChild(eventEditionSubSectionTextArea)

    divFlex.appendChild(divFlexItem)
    divFlex.appendChild(divFlexItem2)
    divFlex1.appendChild(divFlexItem11)
    divFlex1.appendChild(divFlexItem12)
    divFlex1.appendChild(divFlexItem4)
    divFlex1.appendChild(divFlexItem5)
    divFlex2.appendChild(divFlexItem1)
    divFlex2.appendChild(divFlexItem3)
    divFlex3.appendChild(divFlexItem6)
    divFlex3.appendChild(divFlexItem7)
    divFlex4.appendChild(divFlexItem9)
    divFlex4.appendChild(divFlexItem10)
    divFlex5.appendChild(divFlexItem8)
    divItem.appendChild(divFlex)
    divItem.appendChild(divFlex1)
    divItem.appendChild(divFlex2)
    divItem.appendChild(divFlex3)
    divItem.appendChild(divFlex4)
    divItem.appendChild(divFlex5)
    var hr = document.createElement("hr")
    divItem.appendChild(hr)

    this.populateSections(eventEditionSectionSelect)
    this.populateSubSections(eventEditionSubSectionSelect)

    this.presentationsWrapperTarget.append(divItem)
    this.removeEventEditionButtonTarget.classList.remove("is-display-none")

    let trixInputHidden = document.getElementById("trix-input-" + eventEditionSubSectionTextArea.getAttribute("trix-id"))
    trixInputHidden.name = "presentation[event_editions_presentations_attributes][" + itemsCount + "][complementary_material]"
  }

  removeEventEditionPresentation(event) {
    const child = this.presentationsWrapperTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      let eventEdition = child.parentElement.querySelector("[data-target='presentations.eventEditionId']")
      let answer = this.removeEditionConfirmationBox()
      if (answer) {
        if (!(eventEdition.value == "") || (eventEdition.value == undefined)) {
          this.removeProductsAndSpeakersByEventEditionId(eventEdition.value)
        }
        this.presentationsWrapperTarget.lastElementChild.remove()
      }
    }
    document.querySelectorAll("div[data-target='presentations.presentationsWrapper'] .item").length > 0 ? this.removeEventEditionButtonTarget.classList.remove("is-display-none") : this.removeEventEditionButtonTarget.classList.add("is-display-none")
  }

  removeProductsAndSpeakersByEventEditionId(eventEditionId) {
    this.getRequest("/produtos/filtro/buscar-por-edicao?event_edition_id=" + eventEditionId).then(productIds => {
      productIds.forEach(productId => {
        this.choiceProductIds.removeActiveItemsByValue(productId.toString())
        this.choiceProductIds.removeActiveItemsByValue(productId)
      })
    })
    this.getRequest("/palestrantes/filtro/buscar-por-edicao?event_edition_id=" + eventEditionId).then(speakerIds => {
      speakerIds.forEach(speakerId => {
        this.choiceSpeakerIds.removeActiveItemsByValue(speakerId.toString())
        this.choiceSpeakerIds.removeActiveItemsByValue(speakerId)
      })
    })
  }

  removeProductsAndSpeakersByEventEditionIdCheckBoxListener(event) {
    let deleteEventEditionCheckBox = event.target.parentElement.parentElement.parentElement.querySelector("[data-target='presentations.deleteEventEditionCheckBox']")
    if (deleteEventEditionCheckBox.checked) {
      let answer = this.removeEditionConfirmationBox()
      if (answer) {
        let eventEdition = event.target.parentElement.parentElement.parentElement.querySelector("[data-target='presentations.eventEditionId']")
        if (!(eventEdition.value == "") || (eventEdition.value == undefined)) {
          this.removeProductsAndSpeakersByEventEditionId(eventEdition.value)
        }
      } else {
        deleteEventEditionCheckBox.checked = false
      }
    }
  }

  async getRequest(url) {
    const response = await fetch(url)
    return await response.json()
  }

  removeEditionConfirmationBox() {
    let answer = window.confirm("Ao remover esta edição, os produtos e palestrantes relacionados a ela também serão removidos deste conteúdo/palestra. Tem certeza que deseja remover esta edição?")
    return answer
  }

  copyRoomUrl() {
    var copyText = document.getElementById("presentation_room_url")
    if ((copyText.value != "") && (copyText.value != undefined)) {
      copyText.select()
      document.execCommand("copy")
      alert("URL copiada: " + copyText.value)
    }
  }

  copyUrl(event) {
    let copyText = event.target.dataset.url
    if (copyText) {
      let input = document.createElement('input')
      document.body.appendChild(input)
      input.value = copyText
      input.select()
      document.execCommand("copy")
      alert("URL copiada: " + input.value)
    }
  }

  submitForm() {
    let form = document.querySelector("form#presentations-form")
    fetch("/palestrantes/buscar-conteudos?ids=" + (this.choiceSpeakerIds ? encodeURIComponent(this.choiceSpeakerIds.getValue(true)) : '')).then(
      function(response) {
        return response.json().then(function(json) {
          let textResult = ""
          for (var i = 0; i < json.length; i++) {
            textResult += `${i > 0 ? '\n' : ''}O(a) palestrante ${json[i].speaker.name_only} já tem conteúdo(s) nesta edição de evento: `
            for (var j = 0; j < json[i].presentations.length; j++) {
              textResult += json[i].presentations[j].title
              if (json[i].presentations.length > 1 && j < (json[i].presentations.length - 1))
                textResult += ', '
            }
          }

          if (textResult != "") {
            var r = confirm(textResult)
            if (r == true) {
              form.dispatchEvent(new Event('submit', { bubbles: true }))
            }
          } else {
            form.dispatchEvent(new Event('submit', { bubbles: true }))
          }
        })
      }
    )
  }

  startVisualizationsZipDownload(event) {
    var r = confirm(`Confirmar o download das visualizações?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/conteudos/download_visualizations.zip?" + new URLSearchParams({
          search: JSON.stringify({
            event_edition_start_at_gteq: document.querySelector("#event_edition_start_at_gteq").disabled ? '' : document.querySelector("#event_edition_start_at_gteq").value,
            event_edition_start_at_lteq: document.querySelector("#event_edition_start_at_lteq").disabled ? '' : document.querySelector("#event_edition_start_at_lteq").value,
            presentation_start_at_gteq: document.querySelector("#presentation_start_at_gteq").disabled ? '' : document.querySelector("#presentation_start_at_gteq").value,
            presentation_start_at_lteq: document.querySelector("#presentation_start_at_lteq").disabled ? '' : document.querySelector("#presentation_start_at_lteq").value,
            q: {
              presentation_title_i_cont: document.querySelector("#q_presentation_title_i_cont").disabled ? '' : document.querySelector("#q_presentation_title_i_cont").value,
              speakers_name_or_speakers_email_i_cont: document.querySelector("#q_speakers_name_or_speakers_email_i_cont").disabled ? '' : document.querySelector("#q_speakers_name_or_speakers_email_i_cont").value,
              event_edition_title_i_cont: document.querySelector("#q_event_edition_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_i_cont").value,
              custom_materials_status: document.querySelector("#q_custom_materials_status").disabled ? '' : document.querySelector("#q_custom_materials_status").value
            }
          })
        }))
        .then(res => {
          return res.text()
        }).then(data => {
          alert('Um email será enviado para você com o link para download.')
          event.target.style.pointerEvents = null
        })
    }
  }

  startZipDownload(event) {
    var r = confirm(`Confirmar o download dos conteúdos?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/conteudos/download.zip?" + new URLSearchParams({
          search: JSON.stringify({
            event_edition_start_at_gteq: document.querySelector("#event_edition_start_at_gteq").disabled ? '' : document.querySelector("#event_edition_start_at_gteq").value,
            event_edition_start_at_lteq: document.querySelector("#event_edition_start_at_lteq").disabled ? '' : document.querySelector("#event_edition_start_at_lteq").value,
            presentation_start_at_gteq: document.querySelector("#presentation_start_at_gteq").disabled ? '' : document.querySelector("#presentation_start_at_gteq").value,
            presentation_start_at_lteq: document.querySelector("#presentation_start_at_lteq").disabled ? '' : document.querySelector("#presentation_start_at_lteq").value,
            q: {
              presentation_title_i_cont: document.querySelector("#q_presentation_title_i_cont").disabled ? '' : document.querySelector("#q_presentation_title_i_cont").value,
              speakers_name_or_speakers_email_i_cont: document.querySelector("#q_speakers_name_or_speakers_email_i_cont").disabled ? '' : document.querySelector("#q_speakers_name_or_speakers_email_i_cont").value,
              event_edition_title_i_cont: document.querySelector("#q_event_edition_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_i_cont").value,
              custom_materials_status: document.querySelector("#q_custom_materials_status").disabled ? '' : document.querySelector("#q_custom_materials_status").value
            }
          })
        }))
        .then(res => {
          return res.text()
        }).then(data => {
          alert('Um email será enviado para você com o link para download.')
          event.target.style.pointerEvents = null
        })
    }
  }

  changeSource() {
    if (this.sourceTarget.value == "twitch") {
      this.liveChannelNameDivTarget.classList.remove("is-display-none")
      this.youtubeVideoUrlTarget.classList.add("is-display-none")
    } else {
      this.liveChannelNameDivTarget.classList.add("is-display-none")
      this.youtubeVideoUrlTarget.classList.remove("is-display-none")
      this.liveChannelNameTarget.value = ""
    }
  }

  setTwitchVideoUrl(event) {
    if (this.liveChannelNameTarget.value == "") {
      this.videoUrlTarget.value = ""
    } else {
      let twitchDomain = "https://player.twitch.tv/?channel="
      let twitchParams = "&parent=eventos.congresse.me&parent=dashboard.congresse.me&controls=false"
      this.videoUrlTarget.value = twitchDomain + this.liveChannelNameTarget.value + twitchParams
    }
  }
}