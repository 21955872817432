// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"
import Picker from "vanilla-picker"

export default class extends Controller {

  static targets = [
    "kind",
    "presentationsDiv",
    "connectedProductsDiv",
    "eventEdition",
    "productId",
    "levelDiv",
    "accessDaysDiv",
    "productFeesCount",
    "form",
    "status",
    "templateDiv",
    "certificateWorkloadDiv",
    "certificateCommunicationDiv",
    "isTicket",
    "qrCodeTypeDiv"
  ]

  initialize() {
    this.choiceConfig = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando...",
      searchEnabled: true
    }

    if (document.querySelector('#product_presentation_ids'))
      this.choicePresentationIds = new Choices(document.querySelector('#product_presentation_ids'), this.choiceConfig)

    if (document.querySelector('#product_connected_product_ids'))
      this.choiceConnectedProductIds = new Choices(document.querySelector('#product_connected_product_ids'), this.choiceConfig)

    this.loadCKEditor()
    this.loadCertificateCommunicationCKEditor()
  }

  connect() {
    let block_color = document.querySelector("#block_color")
    let picker1 = new Picker(block_color)
    picker1.onChange = function(color) {
      document.querySelector("#product_block_color").value = color.rgbaString
      block_color.style.background = color.rgbaString
    }

    if (document.querySelector("#product_items")) {
      this.loadTypeItems("#product_items")
    }

    if (document.querySelector("#product_bonus_items")) {
      this.loadTypeItems("#product_bonus_items")
    }

    if (this.hasKindTarget) {
      this.changeKind()
    }

    if (this.hasIsTicketTarget){
      this.changeIsTicket()
    }
  }

  loadPresentations() {
    self = this
    self.choicePresentationIds.clearStore()
    let event_edition = []
    if (self.eventEditionTarget.value == "") {

    } else {
      event_edition = self.eventEditionTarget.value

      self.choicePresentationIds.setChoices(function(callback) {
        return fetch(
          "/filtrar-conteudos/" + event_edition
        )
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          return [{value: '', label: ''}].concat(data.map(function(presentation) {
            return { value: presentation.id, label: presentation.title }
          }))
        })
      })
    }
  }

  loadTypeItems(selector) {
    let config = {
      searchResultLimit: 15,
      searchChoices: false,
      delimiter: ";",
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }
    let input = document.querySelector(selector)
    let choice = new Choices(input, config)
    let inputDup = input.parentElement.getElementsByClassName("choices__input--cloned")[0]
    let keyboardEnter = new KeyboardEvent('keydown', {
      code: 'Enter',
      key: 'Enter',
      charKode: 13,
      keyCode: 13,
      view: window
    });
  }

  loadCKEditor() {
    if (document.querySelector("#product_description")) {
      let toolbarConfig = [
        { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
        { name: 'styles', items: [ 'Font', 'FontSize' ] },
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'CopyFormatting' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
        { name: 'links', items: [ 'Link', 'Unlink' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' ] },
        { name: 'insert', items: ['base64image', 'Table'] },
        { name: 'editing', items: [ 'Scayt' ] }
      ]

      let config = {
        customConfig: '/js/ckeditor_config.js',
        toolbar: toolbarConfig,
        wordcount: {
          showWordCount: true,
          showCharCount: false
        }
      }

      if (CKEDITOR.instances.hasOwnProperty("product_description")) {
        CKEDITOR.instances.product_description.destroy()
      }
      CKEDITOR.replace(document.querySelector('#product_description'), config)
    }
  }

  changeKind(){
    if (this.kindTarget.value == "independent") {
      this.connectedProductsDivTarget.classList.add("is-display-none")
      this.presentationsDivTarget.classList.remove("is-display-none")
      this.levelDivTarget.classList.remove("is-display-none")
      this.accessDaysDivTarget.classList.remove("is-display-none")
      this.templateDivTarget.classList.remove("is-display-none")
      this.certificateWorkloadDivTarget.classList.remove("is-display-none")
      this.certificateCommunicationDivTarget.classList.remove("is-display-none")
      // this.loadPresentations()
    } else {
      this.connectedProductsDivTarget.classList.remove("is-display-none")
      this.presentationsDivTarget.classList.add("is-display-none")
      this.levelDivTarget.classList.add("is-display-none")
      this.accessDaysDivTarget.classList.add("is-display-none")
      this.templateDivTarget.classList.add("is-display-none")
      this.certificateWorkloadDivTarget.classList.add("is-display-none")
      this.certificateCommunicationDivTarget.classList.add("is-display-none")
    }
  }

  submitForm(event){
    if ((parseInt(this.productFeesCountTarget.dataset.value) == 0) && (this.statusTarget.checked)){
      let textResult = "Você está iniciando as vendas deste produto. Confira se você já cadastrou os recebedores e suas taxas. ATENÇÃO: A receita proveniente de vendas já realizadas não poderá ter sua divisão alterada à posteriori."
      var r = confirm(textResult)
      if (r == true) {
        this.formTarget.dispatchEvent(new Event('submit', {bubbles: true}))
      }
    } else {
      this.formTarget.dispatchEvent(new Event('submit', {bubbles: true}))
    }
  }

  copyProductCheckoutUrl(event) {
    let copyText = event.currentTarget.dataset.value
    if (copyText) {
      let input = document.createElement('input')
      document.body.appendChild(input)
      input.value = copyText
      input.select()
      document.execCommand("copy")
      alert(`URL copiada: ${input.value}`)
    }
  }

  loadCertificateCommunicationCKEditor() {
    if (document.querySelector("#product_certificate_communication")) {
      let toolbarConfig = [
        { name: 'basicstyles', items: [ 'Bold', 'Italic' ] }
      ]

      let config = {
        toolbar: toolbarConfig
      }

      if (CKEDITOR.instances.hasOwnProperty("product_certificate_communication")) {
        CKEDITOR.instances.product_certificate_communication.destroy()
      }
      CKEDITOR.replace(document.querySelector('#product_certificate_communication'), config)
    }
  }

  changeIsTicket(){
    if (this.isTicketTarget.checked) {
      this.qrCodeTypeDivTarget.classList.remove('is-display-none')
    } else {
      this.qrCodeTypeDivTarget.classList.add('is-display-none')
    }
  }
}