// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "listEventEditionAlerts", "bodyText" ]

  initialize() {
  }

  connect() {
  }

  runAtChange() {
    self = this
    let event_edition_id = document.getElementById("event_editions_alert_event_edition_id").value
    let day = document.getElementById("event_editions_alert_run_at_3i").value
    let month = document.getElementById("event_editions_alert_run_at_2i").value - 1
    let year = document.getElementById("event_editions_alert_run_at_1i").value
    let hour = document.getElementById("event_editions_alert_run_at_4i").value
    let minute = document.getElementById("event_editions_alert_run_at_5i").value
    var date = new Date(year, month, day, hour, minute).toJSON()

    fetch(`/edicoes-de-evento/${event_edition_id}/alertas-de-eventos/buscar-data?run_at=${date}`).then(
      function(response) {
        if (response.ok) {
          return response.json().then(function(json) {
            let html = `<span style="font-weight: bold;">Assunto: </span>${json.subject}<br><br>${json.text}`
            self.bodyTextTarget.innerHTML = html
          })
        } else {
          self.bodyTextTarget.innerHTML = ''
        }
      }
    )
  }
}
