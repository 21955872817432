// JS Controller for global methods and functions

import { Controller } from "stimulus"

import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

export default class extends Controller {

  static targets = [ "eventEdition", "users", "products", "expired", "expiretAt", "expiredAtDiv" ]

  initialize() {
    this.flatpickrConfig = {
      enableTime: true,
      altInput: true,
      altFormat: "j/m/Y H:i:s",
      locale: Portuguese      
    }
  }

  connect() {
    if (this.hasUsersTarget){
      this.enableOrDisableUsers()
    }
    if (this.hasExpiretAtTarget){
      this.flatPickrInstance = flatpickr(".datetime-select-input", this.flatpickrConfig)
      this.showOrHideExpiredAtField()
    }
  }

  showOrHideExpiredAtField(){    
    if (this.expiredTarget.checked) {
      this.expiredAtDivTarget.classList.remove("is-display-none")
    } else {
      this.expiredAtDivTarget.classList.add("is-display-none")
      this.flatPickrInstance.clear()
    }
  }

  enableOrDisableUsers() {    
    if (this.eventEditionTarget.value == ""){
      this.usersTarget.disabled = true
    } else {
      this.usersTarget.disabled = false
    }
  }

  changeEventEdition(event) {
    var productsTarget = this.productsTarget
    var products = []
    if (event.currentTarget.value == "") {
      productsTarget.options.length = 1
    } else {
      for (var i = 0; i < event.currentTarget.options.length; i++) {
        products.push(event.currentTarget.options[i].value)
      }

      fetch("/filtrar-produtos/" + products).then(
        function(response) {
          return response.json().then(function(json) {
            productsTarget.options.length = 1
            for (var i = 0; i < json.length; i++) {
              var option = document.createElement("option")
              option.appendChild(document.createTextNode(json[i]["name"]))
              option.value = json[i]["id"]
              productsTarget.appendChild(option)
            }
          })
        }
      )
    }
  }

  getUsers(event) {
    var usersList = document.getElementById("users-list")
    fetch("/usuarios/filtrar-usuarios/?event_edition_id=" + this.eventEditionTarget.value + "&q=" + event.currentTarget.value).then(
      function(response) {
        return response.json().then(function(json) {
          usersList.innerHTML = ""
          for (var i = 0; i < json.length; i++) {
            var option = document.createElement("option")
            option.value = json[i]["label"]
            option.dataset.user = json[i]["value"]
            usersList.appendChild(option)
          }
        })
      }
    )
  }

  changeUsersList(event){
    var usersList = document.getElementById("users-list")
    var opts = usersList.childNodes;
    var option = ""
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === this.usersTarget.value) {
        option = opts[i].getAttribute("data-user")
        break;
      }
    }
    document.getElementById("transaction_user_id").value = option
  }

  generateTributationReport(event){
    var r = confirm(`Confirmar o download do relatório de transações para tributação?`)
    event.target.style.pointerEvents = "none"
    if (r == true) {      
      fetch("/transacoes/relatorio-para-tributacao?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value          
        })
      }))      
      .then(res => {
        return res.json()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')        
      })
    }
    event.target.style.pointerEvents = null
  }

  startZipDownload(event){
    var r = confirm(`Confirmar download?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/transacoes/download.zip?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          transaction_id_i_cont: document.querySelector("#q_transaction_id_i_cont").disabled ? '' : document.querySelector("#q_transaction_id_i_cont").value,            
          product_name_i_cont: document.querySelector("#q_product_name_i_cont").disabled ? '' : document.querySelector("#q_product_name_i_cont").value,
          user_name_or_user_email_i_cont: document.querySelector("#q_user_name_or_user_email_i_cont").disabled ? '' : document.querySelector("#q_user_name_or_user_email_i_cont").value,
          event_edition_event_short_title_or_event_edition_title_i_cont: document.querySelector("#q_event_edition_event_short_title_or_event_edition_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_event_short_title_or_event_edition_title_i_cont").value,
          seller_name_or_seller_email_i_cont: document.querySelector("#q_seller_name_or_seller_email_i_cont").disabled ? '' : document.querySelector("#q_seller_name_or_seller_email_i_cont").value
        })        
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }
}
