// JS Controller for global methods and functions
import { Controller } from "stimulus"
import Choices from "choices.js"
import IMask from "imask"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import { dateToSqlFormat } from "../packs/global_scripts"

var searchDiv = ""

export default class extends Controller {

  static targets = [ "formErrors", "aside", "menuBars", "eventMenu", "iconUser", "accountLink", "eventEditions", "provinces", "cities", "search" ]

  initialize() {
    this.changeWindowLayout()
  }

  connect() {
    if (document.querySelector("div[data-target='application.search']")) {
      searchDiv = this.searchTarget
    }

    // Active url aside
    let locationHref = window.location.href.split('?')[0]
    let url = locationHref.split("/")
    if (url.length == 6){      
      if (url["5"] == "configuracoes-de-resumos"){
        document.querySelectorAll("aside li").forEach((item, i) => {
          item.querySelector("a").classList.remove("active")          
        })
        this.getElementsByText('Configurações')[0].classList.add("active")
      }
    } else {
      if ((url[3] == "cgme_admin" && (url[4] != "sessao" && url[4] != "senhas")) || (url[3] != "cgme_admin" && url[3] != "" && url[3] != "sessao" && url[3] != "senhas" && url[4] != "sessao" && url[4] != "senhas" ) ) {
        document.querySelectorAll("aside li").forEach((item, i) => {
          item.querySelector("a").classList.remove("active")
          if (url[3] != "cgme_admin") {
            if (item.querySelector("a").href.split("/")[3] == url[3]) {
              item.querySelector("a").classList.add("active")
            } else {
              if (url["3"] == "categorias" || url["3"] == "paises" || url["3"] == "provincias" || url["3"] == "cidades") {
                if (item.querySelector("a").href.split("/")[5] == "configuracoes") {
                  item.querySelector("a").classList.add("active")
                }
              }
            }
          } else {
            if (item.querySelector("a").href.split("/")[5] == url[5] && url[5] != undefined) {
              item.querySelector("a").classList.add("active")
            } else {
              if (item.querySelector("a").href.split("/")[4] == "administradores" && url[5] != "dashboard") {
                if (item.querySelector("a").href.split("/")[5] == "configuracoes") {
                  item.querySelector("a").classList.add("active")
                }
              }
            }
          }
        })
      }
    }    

    // Initialize Choices
    document.querySelectorAll("input[type='text'].choices").forEach(
      function(currentValue, currentIndex, listObj) {
        new Choices(currentValue, {
          removeItems: true,
          removeItemButton: true,
          editItems: true,
          addItems: true,
          searchResultLimit: 15,
          itemSelectText: "Pressione enter para adicionar",
          fuseOptions: {
            threshold: 0.2,
            matchAllTokens: true,
            tokenize: true
          }
        })
      }
    )
    this.initializeSelects(document.querySelectorAll("input[type='text'].choices"))

    // Initialize Choices
    document.querySelectorAll("select.choices").forEach(
      function(currentValue, currentIndex, listObj) {
        new Choices(currentValue, {
          position: "bottom",
          removeItems: true,
          removeItemButton: true,
          searchResultLimit: 15,
          sorter: function() { return },
          noChoicesText: 'Nenhum item para ser selecionado',
          itemSelectText: "Pressione enter para adicionar",
          fuseOptions: {
            threshold: 0.2,
            matchAllTokens: true,
            tokenize: true
          }
        })
      }
    )
    this.initializeSelects(document.querySelectorAll("select.choices"))

    // Initialize Choice
    document.querySelectorAll("select.choice").forEach(
      function(currentValue, currentIndex, listObj) {
        new Choices(currentValue, {
          position: "bottom",
          removeItems: false,
          removeItemButton: false,
          searchResultLimit: 15,
          sorter: function() { return },
          noChoicesText: 'Nenhum item para ser selecionado',
          itemSelectText: "Pressione enter para adicionar",
          searchEnabled: true,
          fuseOptions: {
            threshold: 0.2,
            matchAllTokens: true,
            tokenize: true
          }
        })
      }
    )
    this.initializeSelects(document.querySelectorAll("select.choice"))
    
    // Initialize Search Choice
    document.querySelectorAll("select.search-choice").forEach(
      function(currentValue, currentIndex, listObj) {
        new Choices(currentValue, {
          position: "bottom",
          removeItems: true,
          removeItemButton: true,
          searchResultLimit: 15,
          sorter: function() { return },
          noChoicesText: 'Nenhum item para ser selecionado',
          itemSelectText: "",
          searchEnabled: false
        })
      }
    )
    this.initializeSelects(document.querySelectorAll("select.search-choice"))

    // Mask money
    const moneyOptions = {
      mask: [
        { mask: '0{,00}' },
        { mask: '00{,00}' },
        { mask: '000{,00}' },
        { mask: '0.000{,00}' },
        { mask: '00.000{,00}' },
        { mask: '000.000{,00}' },
        { mask: '0.000.000{,00}' },
        { mask: '00.000.000{,00}' },
        { mask: '000.000.000{,00}' },
        { mask: '0.000.000.000{,00}' }
      ]
    }

    // set mask on your input you can pass a querySelector or your input element and options
    document.querySelectorAll(".input-money").forEach(
      function(currentValue, currentIndex, listObj) {
        currentValue.onfocus = (e) => {
          IMask(currentValue, moneyOptions)
        }
      }
    )

    document.querySelectorAll(".input-number").forEach(
      function(currentValue, currentIndex, listObj) {
        currentValue.onfocus = (e) => {
          IMask(currentValue, { mask: Number })
        }
      }
    )

    // Datepicker
    let flatpickrConfig = {
      mode:'range',
      altInput: true,
      altFormat: "j/m/Y",
      locale: Portuguese,
      onChange: function(selectedDates, dateStr, instance){
        if (selectedDates[0]) {
          let from = dateToSqlFormat(selectedDates[0]); 
          instance.element.parentElement.querySelector("#q_created_at_gteq, #q_updated_at_gteq, #q_start_at_gteq, #start_at_gteq, #event_edition_start_at_gteq, #presentation_start_at_gteq").value = from
        } else {
          instance.element.parentElement.querySelector("#q_created_at_gteq, #q_updated_at_gteq, #q_start_at_gteq, #start_at_gteq, #event_edition_start_at_gteq, #presentation_start_at_gteq").value = ''
        }
  
        if (selectedDates[1]) {
          let to = dateToSqlFormat(selectedDates[1]);
          instance.element.parentElement.querySelector("#q_created_at_lteq, #q_updated_at_lteq, #q_start_at_lteq, #start_at_lteq, #event_edition_start_at_lteq, #presentation_start_at_lteq").value = to
        } else {
          instance.element.parentElement.querySelector("#q_created_at_lteq, #q_updated_at_lteq, #q_start_at_lteq, #start_at_lteq, #event_edition_start_at_lteq, #presentation_start_at_lteq").value = ''
        }
      }
    }

    document.querySelectorAll(".datetime-range-select-input").forEach(
      function(currentValue, currentIndex, listObj) {
        flatpickr(currentValue, flatpickrConfig)
      }
    )
  }

  // Function to initialize selects with padding preserved
  initializeSelects(events) {
    events.forEach((item, i) => {
      if (item.value == "") {
        item.parentElement.classList.remove("has-options")
      } else {
        item.parentElement.classList.add("has-options")
      }
    })
  }

  // Function to preserve padding layout with item is selected
  changeSelect(event) {    
    if (event.target.value == "") {
      event.target.parentElement.classList.remove("has-options")
    } else {
      event.target.parentElement.classList.add("has-options")
    }
  }

  // Change model on search form
  changeSearchModel(event) {
    let divs = document.querySelectorAll('div[data-model-search]')
    for (let i = 0; i < divs.length; i++) {
      divs[i].classList.add("is-display-none")
      if (divs[i].querySelector("input"))
        divs[i].querySelector("input").disabled = true
      if (divs[i].querySelector(".choices"))
        divs[i].querySelector("select").choices.disable()
      if (divs[i].querySelector("select"))
        divs[i].querySelector("select").disabled = true
    }

    if (event.target.value) {
      document.querySelector(`div[data-model-search=${event.target.value}]`).classList.remove("is-display-none")
      if (document.querySelector(`div[data-model-search=${event.target.value}]`).querySelector("input"))
        document.querySelector(`div[data-model-search=${event.target.value}]`).querySelector("input").disabled = false
      if (document.querySelector(`div[data-model-search=${event.target.value}]`).querySelector("select")) {
        document.querySelector(`div[data-model-search=${event.target.value}]`).querySelector("select").disabled = false
      }
      if (document.querySelector(`div[data-model-search=${event.target.value}]`).querySelector(".choices")) {
        document.querySelector(`div[data-model-search=${event.target.value}]`).querySelector("select").choices.enable()
      }
    } else {
      document.querySelector("div[data-model-search=default]").classList.remove("is-display-none")
      document.querySelector("div[data-model-search=default]").querySelector("input").disabled = false
    }
  }

  // Change model on search form
  changeSearchModelDate(event) {
    let divs = document.querySelectorAll('div[data-model-date-search]')
    for (let i = 0; i < divs.length; i++) {
      divs[i].classList.add("is-display-none")
      let inputs = divs[i].querySelectorAll("input")
      for (let j = 0; j < inputs.length; j++) {
        inputs[j].disabled = true
      }
    }

    if (event.target.value) {
      document.querySelector(`div[data-model-date-search=${event.target.value}]`).classList.remove("is-display-none")
      if (document.querySelector(`div[data-model-date-search=${event.target.value}]`).querySelector("input")) {
        let inputs = document.querySelector(`div[data-model-date-search=${event.target.value}]`).querySelectorAll("input")
        for (let j = 0; j < inputs.length; j++) {
          inputs[j].disabled = false
        }
      }
    } else {
      document.querySelector("div[data-model-date-search=default]").classList.remove("is-display-none")
      let inputs = document.querySelector("div[data-model-date-search=default]").querySelectorAll("input")
      for (let j = 0; j < inputs.length; j++) {
        inputs[j].disabled = false
      }
    }
  }

  // Function for show or hide objects window innerWidth according
  changeWindowLayout() {
    let url = location.href.split("/")
    if (url[3] != "" && url[3] != "sessao" && url[3] != "senhas" && url[4] != "sessao" && url[4] != "senhas") {
      let aside = this.asideTarget
      let menuBars = this.menuBarsTarget
      let iconUser = this.iconUserTarget
      let accountLink = this.accountLinkTarget
      if (window.innerWidth < 576) {
        aside.classList.remove("full")
        menuBars.classList.add("is-display-none")
        accountLink.classList.add("is-display-none")
        iconUser.classList.add("is-display-initial")
      } else {
        menuBars.classList.remove("is-display-none")
        accountLink.classList.remove("is-display-none")
        iconUser.classList.remove("is-display-initial")
      }
    }
  }

  // Function for prevent browser events
  preventDefault(event) {
    event.preventDefault()
  }

  // Function for change page informations
  recoverPassword() {
    document.getElementById("form").classList.remove("is-display-block")
    document.getElementById("passwordInstructions").classList.add("is-display-block")
  }

  // Function for full aside show or hide
  changeAside() {
    if (this.asideTarget.classList.contains("full")) {
      this.asideTarget.classList.remove("full")
    } else {
      this.asideTarget.classList.add("full")
    }
  }

  // Error form
  onPostError(event) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.formErrorsTarget.innerText = ""
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error))
      this.formErrorsTarget.appendChild(li)
    }
  }

  // Success form
  onPostSuccess(event) {
    this.formErrorsTarget.innerText = ""
    let [data, status, xhr] = event.detail
  }

  //filterEventSuccess
  filterEventSuccess(event){
    let filterReturn = event.detail[0]
    if (filterReturn.isEventManager){
      window.location.href = filterReturn.initialPage
    } else {
      document.location.reload(true)
    }
  }

  // Open header modal
  openHeaderModal(event) {
    if (event.target.parentNode.querySelector(".modal") != null){
      event.target.parentNode.querySelector(".modal").style.display = "block"
    } else if (event.target.parentNode.parentNode.querySelector(".modal") != null){
      event.target.parentNode.parentNode.querySelector(".modal").style.display = "block"
    } else {
      event.target.parentNode.parentNode.parentNode.querySelector(".modal").style.display = "block"
    }
    document.getElementById("event_manager_event_id").parentNode.classList.add("has-options")
    document.getElementById("event_manager_event_edition_id").parentNode.classList.add("has-options")
  }

  // Open modal
  openModal(event) {
    event.target.parentNode.nextElementSibling.style.display = "block"
  }

  // Close modal in click close icon
  closeModal() {
    document.querySelector('.modal[style*="display: block"]').style.display = "none"
  }

  // Close modal in click out modal area
  closeModalWithClickOut(event) {
    var modal = document.querySelector('.modal[style*="display: block"]')
    if (event.target == modal) {
      modal.style.display = "none"
    }
  }

  // Function to call an click input
  triggeredInput(event) {
    event.target.parentNode.parentNode.querySelector("input[type='file']").click()
  }

  // Function to change image if button is clicked
  changeImage(event) {
    const file = event.target.files[0]
    const img = event.target.parentNode.querySelector(".picture")
    const reader = new FileReader()
    reader.readAsDataURL(file, "UTF-8")
    reader.onload = function(e) {
      img.src = e.target.result
      const icon = event.target.parentNode.querySelector(".icon-image")
      if (icon) {
        icon.classList.add("is-display-none")
      }
      img.classList.remove("is-display-none")
    }
    reader.onerror = function(e) {
      console.log(e)
    }
  }

  // Function to remove image if button is clicked
  removeImage(event) {
    let img = event.target.parentNode.parentNode.querySelector(".picture")
    let divIcon = document.createElement("div")
    let iIcon = document.createElement("i")
    divIcon.classList.add("flex", "ai-center")
    iIcon.classList.add("icon", "icon-image")
    divIcon.appendChild(iIcon)

    img.parentNode.insertBefore(divIcon, img.nextSibling);
    img.classList.add("is-display-none")
  }

  // Function if event is changed in filter
  changeEvent(event) {
    let EventManagerType = 'EventManager'
    var eventEditionsTarget = this.eventEditionsTarget
    if (event.currentTarget.value == "") {
      eventEditionsTarget.options.length = 1
    } else {
      fetch("/filtrar-edicoes-de-evento/" + event.currentTarget.value).then(
        function(response) {
          return response.json().then(function(json) {
            if (json.current_user_type == EventManagerType){
              eventEditionsTarget.options.length = 0
            } else {
              eventEditionsTarget.options.length = 1
            }            
            for (var i = 0; i < json.editions.length; i++) {
              var option = document.createElement("option")
              option.appendChild(document.createTextNode(json.editions[i]["title"]))
              option.value = json.editions[i]["id"]
              eventEditionsTarget.appendChild(option)
            }
          })
        }
      )
    }
  }

  openAccordion(event) {
    let item  = event.currentTarget.querySelector(".item")
    if (item.classList.contains("active")) {
      item.classList.remove("active")
    } else {
      item.classList.add("active")
    }
  }

  changeStep(event) {
    document.querySelectorAll(".tab--form").forEach(function(currentValue) {
      currentValue.classList.remove("is-tab-active")
    })
    event.currentTarget.classList.add("is-tab-active")

    document.querySelectorAll(".steps").forEach(function(currentValue){
      currentValue.classList.add("is-display-none")
    })
    document.querySelector(`.steps.${event.currentTarget.dataset.step}`).classList.remove("is-display-none")
  }

  changeTab(event) {
    event.currentTarget.parentElement.querySelectorAll(".tabs-horizontal button").forEach(function(currentValue) {
      currentValue.classList.remove("is-active")
    })
    event.currentTarget.classList.add("is-active")

    event.currentTarget.parentElement.parentElement.querySelectorAll(".tab-horizontal").forEach(function(currentValue){
      currentValue.classList.add("is-display-none")
    })
    event.currentTarget.parentElement.parentElement.querySelector(`.tab-horizontal.${event.currentTarget.dataset.tab}`).classList.remove("is-display-none")
  }

  filterAsc() {
    // O campo é o data-target-value, o controller pode pegar via getAttribute no body    
  }

  filterDesc() {
    
  }

  // Function to get provinces
  changeCountry(event) {
    var provincesTarget = this.provincesTarget
    var citiesTarget = this.citiesTarget
    provincesTarget.options.length = 1
    citiesTarget.options.length = 1
    if (event.currentTarget.value != "") {
      fetch("/provincias/" + event.currentTarget.value).then(
        function(response) {
          return response.json().then(function(json) {
            for (var i = 0; i < json.length; i++) {
              var option = document.createElement("option")
              option.appendChild(document.createTextNode(json[i]["name"]))
              option.value = json[i]["id"]
              provincesTarget.appendChild(option)
            }
          })
        }
      )
    }
  }

  // Function to get cities
  changeProvince(event) {
    var provincesTarget = this.provincesTarget
    var citiesTarget = this.citiesTarget
    citiesTarget.options.length = 1
    if (event.currentTarget.value != "") {
      fetch("/cidades/" + event.currentTarget.value).then(
        function(response) {
          return response.json().then(function(json) {
            for (var i = 0; i < json.length; i++) {
              var option = document.createElement("option")
              option.appendChild(document.createTextNode(json[i]["name"]))
              option.value = json[i]["id"]
              citiesTarget.appendChild(option)
            }
          })
        }
      )
    }
  }

  onlyNumbersWithOneDecimalPlace(element){
    var ASCIICode = (element.which) ? element.which : element.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false
    } else {
      let currentValue = element.currentTarget.value
      let number = null
      if (currentValue.includes(".") || currentValue.includes(",")){
        number = parseFloat(currentValue).toFixed(1)
      } else {
        number = currentValue
      }
      element.currentTarget.value = number
    }
  }

  getElementsByText(str, tag = 'a') {
    return Array.prototype.slice.call(document.getElementsByTagName(tag)).filter(el => el.textContent.trim() === str.trim());
  }
}
