// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"
const axios = require('axios')
import { v4 as uuidv4 } from 'uuid'

export default class extends Controller {

  static targets = ["presentationsUploadsWrapper", "addFileButton", "removeFileButton", "uploadArea", "eventEdition", "loadingModal", "uploadLoading", "loadingPandaFoldersModal", "files"]

  initialize() {
    this.choiceConfig = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom", 
      removeItems: false,
      removeItemButton: false,     
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }    
  }

  connect() {
    this.videoItemCount = 0
    this.uploads = []
    this.errors = []    
    this.filesArray = []    
    this.selectedPresentations = []
  }

  addFile() {
    this.presentationsUploadsWrapperTarget.insertAdjacentHTML('beforeend', 
    `<li class="flex" id="presentation-upload-li-${this.videoItemCount}">
        <span class="flex-item">
          <input type="file" name="presentation_upload_file_${this.videoItemCount}" id="presentation-upload-file-${this.videoItemCount}" class="is-display-block video-url" data-action="change->presentation-uploads#changePresentationUploadFile" data-value="${this.videoItemCount}">
          <div id="file-error-${this.videoItemCount}" class="is-display-none"></div>
        </span>
        <span class="flex-item">
          <select class="presentations-select" id="presentation-select-${this.videoItemCount}" class="choice"></select>
          <div id="presentation-error-${this.videoItemCount}" class="flex jc-center error">Selecione um conteúdo/palestra ou remova este arquivo da lista</div>
        </span>
        <div class="actions"><a class="icon" data-action="application#preventDefault presentation-uploads#removeFile" data-value="${this.videoItemCount}" href="#">
        <i class="icon-trash" title="Remover Arquivo"></i></a></div>
    </li>`)    
    return this.videoItemCount++
  }

  addNewFile(file){
    this.presentationsUploadsWrapperTarget.insertAdjacentHTML('beforeend', 
    `<li class="flex" id="presentation-upload-li-${this.videoItemCount}">
        <span class="flex-item">
          <div data-value="${this.videoItemCount}">${file.name}</div>
          <div id="file-error-${this.videoItemCount}" class="is-display-none"></div>
        </span>
        <span class="flex-item">
          <select class="presentations-select" id="presentation-select-${this.videoItemCount}" class="choice"></select>
          <div id="presentation-error-${this.videoItemCount}" class="flex jc-center error">Selecione um conteúdo/palestra ou remova este arquivo da lista</div>
        </span>
        <div class="actions"><a class="icon" data-action="application#preventDefault presentation-uploads#removeFile" data-value="${this.videoItemCount}" href="#">
        <i class="icon-trash" title="Remover Arquivo"></i></a></div>
    </li>`)    
    return this.videoItemCount++
  }

  removeFile(event) {    
    var r = confirm("Remover este arquivo da lista?")
    if (r == true) {      
      let elementId = event.target.parentNode.dataset.value
      document.getElementById("presentation-upload-li-" + elementId).outerHTML = ""
      this.updateUploadsArray(elementId)      
    }
    if (this.uploads.length == 0){
      this.filesTarget.value = ""
    }    
  }

  changePresentationUploadFile(event){
    var targetElement = event.target    
    if (targetElement.files.length > 0){
      var elementId = targetElement.dataset.value
      var filename = targetElement.files[0].name
      var presentationId = filename.split("_").pop().split(".")[0]   
      let selectTarget = document.getElementById("presentation-select-" + elementId)
      this.loadPresentations(new Choices(selectTarget, this.choiceConfig), presentationId, elementId)
    }
  }

  loadPresentations(targetElementChoice, presentationId, elementId) {
    targetElementChoice.clearChoices()
    var self = this        
    targetElementChoice.setChoices(function(callback) {
      return fetch(
        "/conteudos/" + presentationId + "/upload-data?event_edition_id=" + self.eventEditionTarget.value
      )
      .then(function(response) {        
        return response.json()
      })
      .then(function(data) {
        return data.map(function(presentation) {          
          return { value: presentation.id, label: presentation.id + " | " + presentation.title, selected: presentation.id == parseInt(presentationId) }
        })
      })      
    })
    targetElementChoice.passedElement.element.addEventListener(
      'addItem',
      function(event) {
        document.getElementById("presentation-error-" + elementId).innerHTML = ""
        document.getElementById("presentation-error-" + elementId).classList.add("is-display-none")        
        self.updateUploadsArray(elementId)
        self.uploads.push({elementId: elementId, fileInput: self.getFileObject(parseInt(elementId)).fileObj, presentationId: event.detail.value, videoId: uuidv4(), eventEditionId: self.eventEditionTarget.value})        
        // if (!(self.selectedPresentations.includes(parseInt(event.detail.value)))){
        //   self.selectedPresentations.push(parseInt(event.detail.value))          
        //   self.updateUploadsArray(elementId)
        //   document.getElementById("presentation-error-" + elementId).classList.add("is-display-none")        
        //   self.uploads.push({elementId: elementId, fileInput: self.getFileObject(parseInt(elementId)).fileObj, presentationId: event.detail.value, videoId: uuidv4(), eventEditionId: self.eventEditionTarget.value})
        // } else {
        //   targetElementChoice.removeActiveItemsByValue(parseInt(event.detail.value))
        //   document.getElementById("presentation-error-" + elementId).innerHTML = "Conteúdo/Palestra já selecionada em outro arquivo. Selecione outro conteúdo/palestra na lista"
        //   self.updateSelectedsPresentationsArray(elementId)
        // }      
      },
      false
    )
    // targetElementChoice.passedElement.element.addEventListener(
    //   'removeItem',
    //   function(event) {  
    //     console.log(event)      
    //     if (self.selectedPresentations.includes(parseInt(event.detail.value))){          
    //       self.updateSelectedsPresentationsArray(elementId)
    //     }
    //   },
    //   false
    // )
  }

  startSend(event){    
    self = this
    self.errors = []    
    document.querySelectorAll(".presentations-select").forEach(e => {
      let targetErrorElement = e.parentNode.parentNode.nextElementSibling      
      if ((e.value == "") || (e.value == undefined)) {        
        targetErrorElement.classList.remove("is-display-none")
        targetErrorElement.innerHTML = "Selecione um conteúdo/palestra ou remova este arquivo da lista"
        self.errors.push({element: e})
      } else {
        targetErrorElement.classList.add("is-display-none")
        targetErrorElement.innerHTML = ""          
      }  
    })

    if (self.checkErrors(self.uploads)) {
      if (self.uploads.length > 0){
        self.loadingModalTarget.style.display = "block"
        var r = confirm("Confirmar envio dos arquivos selecionados?")
        if (r == true) {
          window.onbeforeunload = this.confirmExit
          self.uploads.forEach(function(upload){
            self.uploadLoadingTarget.innerHTML += `<div class="progress">
              <progress value="0" max="100" id="progress-bar-${upload.elementId}"></progress>
              <span class="label">${upload.fileInput.name}: <span id="progress-item-${upload.elementId}">0%</span></span>
            </div>`            
            let data = upload.fileInput            
            let uploadconfig = {
              method: 'post', 
              url: 'https://uploader-us02.pandavideo.com.br/files',
              headers: { 
                'Tus-Resumable': '1.0.0', 
                'Upload-Length': upload.fileInput.size, 
                'Content-Type': 'application/offset+octet-stream', 
                'Upload-Metadata': "authorization cGFuZGEtYmFhNDcyNjhkOTBhYzM1NzkzMGJiODQzM2ZjMTE1MGE4NDA0YWEyMDgzMzMzNmE3YzhkMzhkZDM4MTJmMWM5ZQ==, filename " + window.btoa(unescape(encodeURIComponent(upload.fileInput.name))) + ", folder_id " + window.btoa(unescape(encodeURIComponent(self.targetFolderId))) + ", video_id " + window.btoa(unescape(encodeURIComponent(upload.videoId)))
              },
              data,
              onUploadProgress: function(progressEvent) {                
                let percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)                
                document.getElementById("progress-bar-" + upload.elementId).value = `${percentage}`
                document.getElementById("progress-item-" + upload.elementId).innerHTML = `${percentage}%`
              }
            }            
            axios(uploadconfig)
            .then(function (response) {                
              axios({
                method: 'post',
                url: '/uploads-de-conteudos',
                headers: {
                  "X-CSRF-Token": document.querySelector("[name='csrf-token']").content, 
                  "Content-Type": "application/json"
                },
                data: {
                  presentation_upload: {
                    presentation_id: upload.presentationId,
                    event_edition_id: upload.eventEditionId,
                    original_video_file_name: upload.fileInput.name,
                    video_id: upload.videoId 
                  }                  
                }
              }).then(function (r) {
                self.updateUploadsArray(upload.elementId)
                if (self.uploads.length == 0){
                  self.loadingModalTarget.style.display = "none"                  
                  self.resetDefaults()
                  window.onbeforeunload = null
                  window.location.href = "/uploads-de-conteudos"
                }
              })              
            })
            .catch(function (error) {
              alert("Erro ao enviar o arquivo " + upload.fileInput.name + ". Tente novamente mais tarde...")
            })
          })  
        }      
      } else {
        alert("Nenhum arquivo ou palestra selecionado para envio")
      }
    } else {      
      alert("Revise os erros encontrados e tente novamente")
    }      
  }

  updateUploadsArray(elementId){    
    this.uploads = this.uploads.filter(function(obj) {
      return obj.elementId != parseInt(elementId)
    }) 
  }

  updateSelectedsPresentationsArray(elementId){    
    this.selectedPresentations = this.selectedPresentations.filter(function(obj) {
      return obj != parseInt(elementId)
    }) 
  }
  
  getFileObject(elementId){    
    for (let i = 0; i < this.filesArray.length; i++) {
      if (this.filesArray[i].elementId == parseInt(elementId)) {
        return this.filesArray[i]
      }
    }
  }

  checkErrors(){
    return this.errors.length == 0
  }

  changeFilesInput(event){    
    this.filesArray = []
    for (let i = 0; i < event.target.files.length; i++){  
      let fileTargetCount = this.addNewFile(event.target.files[i])
      this.filesArray.push({elementId: fileTargetCount, fileObj: event.target.files[i]})
      var filename = event.target.files[i].name
      var presentationId = filename.split("_").pop().split(".")[0]
      let selectTarget = document.getElementById("presentation-select-" + fileTargetCount)
      this.loadPresentations(new Choices(selectTarget, this.choiceConfig), presentationId, fileTargetCount)        
    }
  }

  changeEventEdition(){
    this.resetDefaults()
    let self = this
    if (self.eventEditionTarget.value == ""){
      self.uploadAreaTarget.classList.add("is-display-none")
    } else {            
      self.loadingPandaFoldersModalTarget.style.display = "block"
      self.getPandaFolder(self.eventEditionTarget.options[self.eventEditionTarget.selectedIndex].text).then( folder => {      
        if(folder.hasOwnProperty('id')){
          self.uploadAreaTarget.classList.remove("is-display-none")
          self.targetFolderId = folder.id
          self.loadingPandaFoldersModalTarget.style.display = "none"
        } else {
          self.uploadAreaTarget.classList.add("is-display-none")
          self.loadingPandaFoldersModalTarget.style.display = "none"          
          alert("Erro ao buscar pasta de destino em pandavideo.com.br. Por favor, tente novamente...")
        }
      })      
    }
  }

  async getPandaFolder(name){
    const response = await fetch("/uploads-de-conteudos/buscar-pasta?name=" + name)
    return await response.json()
  }

  confirmExit() {
    return "Não feche esta janela. Os arquivos estão sendo enviados..."
  }

  resetDefaults(){
    this.uploads = []
    this.errors = []
    this.filesArray = []
    this.presentationsUploadsWrapperTarget.innerHTML = ""
    this.uploadAreaTarget.classList.add("is-display-none")
    this.targetFolderId = null
    this.filesTarget.value = ""
    this.uploadLoadingTarget.innerHTML = ""
  }

  startZipDownload(event){
    var r = confirm(`Confirmar download?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/uploads-de-conteudos/exportar.csv?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          original_video_file_name_or_uploader_name_or_uploader_email_i_cont: document.querySelector("#q_original_video_file_name_or_uploader_name_or_uploader_email_i_cont").disabled ? '' : document.querySelector("#q_original_video_file_name_or_uploader_name_or_uploader_email_i_cont").value,            
          presentation_title_i_cont: document.querySelector("#q_presentation_title_i_cont").disabled ? '' : document.querySelector("#q_presentation_title_i_cont").value,
          event_edition_title_i_cont: document.querySelector("#q_event_edition_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_i_cont").value
        })        
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }
}
