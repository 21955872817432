// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "subSectionsWrapper", "addSectionButton", "removeSectionButton" ]

  initialize() {
    if (document.querySelector("#event_editions_sub_section_last_order"))
      this.last_section_order = document.querySelector("#event_editions_sub_section_last_order").value || 0
    else
      this.last_section_order = 0
  }

  connect() {
  }

  changeGridType(event) {
    let grid_type = event.currentTarget.value
    if (grid_type == 'with_custom_separator') {
      this.addSectionButtonTarget.classList.remove("is-display-none")
      this.removeSectionButtonTarget.classList.remove("is-display-none")
      document.querySelector('#block-title-wrapper').classList.add("is-display-none")
      document.querySelector('#block-description-wrapper').classList.add("is-display-none")
    } else {
      this.subSectionsWrapperTarget.innerHTML = ''
      this.addSectionButtonTarget.classList.add("is-display-none")
      this.removeSectionButtonTarget.classList.add("is-display-none")
      document.querySelector('#block-title-wrapper').classList.remove("is-display-none")
      document.querySelector('#block-description-wrapper').classList.remove("is-display-none")
    }
  }

  addSubSection(event) {
    var divItem = document.createElement("div")
    divItem.classList.add("item")
    var divItem2 = document.createElement("div")
    divItem2.classList.add("item")
    var divFlex = document.createElement("div")
    divFlex.classList.add("flex")
    var divFlex2 = document.createElement("div")
    divFlex2.classList.add("flex")
    var formGroup = document.createElement("div")
    formGroup.classList.add("flex-item")
    formGroup.classList.add("form-group")
    var input = document.createElement("input")
    input.type = "text"
    input.placeholder = "Nome"
    input.name = `event_editions_section[event_editions_sub_sections_attributes][${this.last_section_order}][name]`
    formGroup.appendChild(input)
    var formGroup2 = document.createElement("div")
    formGroup2.classList.add("flex-item")
    formGroup2.classList.add("form-group")
    var input2 = document.createElement("input")
    input2.name = `event_editions_section[event_editions_sub_sections_attributes][${this.last_section_order}][order]`
    input2.type = "number"
    input2.placeholder = "Ordem (Opcional)"
    formGroup2.appendChild(input2)
    var formGroup3 = document.createElement("div")
    formGroup3.classList.add("flex-item")
    formGroup3.classList.add("form-group")
    var input3 = document.createElement("input")
    input3.name = `event_editions_section[event_editions_sub_sections_attributes][${this.last_section_order}][block_title]`
    input3.type = "text"
    input3.placeholder = "Título da subdivisão"
    formGroup3.appendChild(input3)
    var formGroup4 = document.createElement("div")
    formGroup4.classList.add("flex-item")
    formGroup4.classList.add("form-group")
    var textArea = document.createElement("textarea")
    textArea.name = `event_editions_section[event_editions_sub_sections_attributes][${this.last_section_order}][block_description]`
    textArea.rows = 1
    textArea.placeholder = "Descrição da subdivisão"
    formGroup4.appendChild(textArea)
    divFlex.appendChild(formGroup)
    divFlex.appendChild(formGroup2)
    divFlex2.appendChild(formGroup3)
    divFlex2.appendChild(formGroup4)
    divItem.appendChild(divFlex)
    divItem2.appendChild(divFlex2)
    this.subSectionsWrapperTarget.append(divItem)
    this.subSectionsWrapperTarget.append(divItem2)
    this.last_section_order++
  }

  removeSubSection(event) {
    const child = this.subSectionsWrapperTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      this.subSectionsWrapperTarget.lastElementChild.remove()
    }
  }

  deleteSubSectionAlert(){
    alert("Remova os conteúdos dessa subdivisão antes de excluí-la.")
  }
}
