// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = []

  initialize() {

  }

  connect() {

  }

  copyProductCheckoutUrl(event) {
    let copyText = event.currentTarget.dataset.value
    if (copyText) {
      let input = document.createElement('input')
      document.body.appendChild(input)
      input.value = copyText
      input.select()
      document.execCommand("copy")
      alert(`URL copiada: ${input.value}`)
    }
  }
}