// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Picker from "vanilla-picker"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

export default class extends Controller {

  static targets = [ "eventDataStep", "imagesStep", "socialStep", "textsStep", "permissionsStep", "eventAdvantagesWrapper", "eventTestimonialsWrapper", "iconModal" ]

  initialize() {
    if (document.querySelector("#event_advantage_last_order"))
      this.last_event_advantage_order = document.querySelector("#event_advantage_last_order").value || 0
    else
      this.last_event_advantage_order = 0

      if (document.querySelector("#event_testimonial_last_order"))
      this.event_testimonial_last_order = document.querySelector("#event_testimonial_last_order").value || 0
    else
      this.event_testimonial_last_order = 0

    this.flatpickrConfig = {
      enableTime: true,
      altInput: true,
      altFormat: "j/m/Y H:i",
      locale: Portuguese
    }
  }

  connect() {
    let primary_color = document.querySelector("#primary_color")
    let picker1 = new Picker(primary_color)
    picker1.onChange = function(color) {
      document.querySelector("#event_primary_color").value = color.rgbaString
      primary_color.style.background = color.rgbaString
    }

    let secondary_color = document.querySelector("#secondary_color")
    let picker2 = new Picker(secondary_color)
    picker2.onChange = function(color) {
      document.querySelector("#event_secondary_color").value = color.rgbaString
      secondary_color.style.background = color.rgbaString
    }
    
    let products_page_banner_color = document.querySelector("#products_page_banner_color")
    let picker3 = new Picker(products_page_banner_color)
    picker3.onChange = function(color) {
      document.querySelector("#event_products_page_banner_color").value = color.rgbaString
      products_page_banner_color.style.background = color.rgbaString
    }

    let products_page_header_color = document.querySelector("#products_page_header_color")
    let picker4 = new Picker(products_page_header_color)
    picker4.onChange = function(color) {
      document.querySelector("#event_products_page_header_color").value = color.rgbaString
      products_page_header_color.style.background = color.rgbaString
    }

    if (document.body.getAttribute("data-action").includes("new") || document.body.getAttribute("data-action").includes("edit")) {
      if (document.querySelector("#event_primary_color").value != "") {
        document.querySelector("#primary_color").style.backgroundColor = document.querySelector("#event_primary_color").value
      }

      if (document.querySelector("#event_secondary_color").value != "") {
        document.querySelector("#secondary_color").style.backgroundColor = document.querySelector("#event_secondary_color").value
      }

      if (document.querySelector("#event_products_page_banner_color").value != "") {
        document.querySelector("#products_page_banner_color").style.backgroundColor = document.querySelector("#event_products_page_banner_color").value
      }

      if (document.querySelector("#event_products_page_header_color").value != "") {
        document.querySelector("#products_page_header_color").style.backgroundColor = document.querySelector("#event_products_page_header_color").value
      }      

      flatpickr(".datetime-select-input", this.flatpickrConfig)
    }
  }

  enableShortResumePdf(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_maximum_word_count").closest("div.flex").classList.add("is-display-none")
    } else {
      document.querySelector("#event_maximum_word_count").closest("div.flex").classList.remove("is-display-none")
    }
  }

  enableExtendedResumePdf(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_maximum_extended_word_count").closest("div.flex").classList.add("is-display-none")
    } else {
      document.querySelector("#event_maximum_extended_word_count").closest("div.flex").classList.remove("is-display-none")
    }
  }

  enableMonographyResumePdf(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_maximum_monography_word_count").closest("div.flex").classList.add("is-display-none")
    } else {
      document.querySelector("#event_maximum_monography_word_count").closest("div.flex").classList.remove("is-display-none")
    }
  }

  addEventAdvantage(event) {
    if (this.last_event_advantage_order < 4) {
      var divItem = document.createElement("div")
      divItem.classList.add("item")
      var divFlex2 = document.createElement("div")
      divFlex2.classList.add("flex")
      var formGroup2 = document.createElement("div")
      formGroup2.classList.add("flex-item")
      formGroup2.classList.add("form-group")
      var input2 = document.createElement("input")
      input2.type = "text"
      input2.placeholder = "Ícone"
      input2.dataset.action = "click->events#openIconModal"
      input2.name = `event[event_advantages_attributes][${this.last_event_advantage_order}][icon]`
      input2.id = `event_advantages_attributes_icon_${this.last_event_advantage_order}`
      formGroup2.appendChild(input2)
      var formGroup3 = document.createElement("div")
      formGroup3.classList.add("flex-item")
      formGroup3.classList.add("form-group")
      var textArea = document.createElement("textarea")
      textArea.name = `event[event_advantages_attributes][${this.last_event_advantage_order}][description]`
      textArea.rows = 1
      textArea.placeholder = "Descrição da Vantagem"
      textArea.maxLength = 111
      formGroup3.appendChild(textArea)
      divFlex2.appendChild(formGroup2)
      divFlex2.appendChild(formGroup3)
      divItem.appendChild(divFlex2)
      this.eventAdvantagesWrapperTarget.append(divItem)
      this.last_event_advantage_order++
    }
  }

  removeEventAdvantage(event) {
    const child = this.eventAdvantagesWrapperTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      this.eventAdvantagesWrapperTarget.lastElementChild.remove()
      this.last_event_advantage_order--
    }
  }

  removeEventAdvantageAlert(event) {
    if (event.currentTarget.checked)
      alert('Atenção! Você deseja excluir esta vantagem?');
  }

  addEventTestimonial(event) {
    if (this.event_testimonial_last_order < 3) {
      var divItem = document.createElement("div")
      divItem.classList.add("item")
      var divFlex2 = document.createElement("div")
      divFlex2.classList.add("flex")
      var formGroup2 = document.createElement("div")
      formGroup2.classList.add("flex-item")
      formGroup2.classList.add("form-group")
      var input2 = document.createElement("input")
      input2.type = "text"
      input2.placeholder = "Nome do Participante"
      input2.name = `event[event_testimonials_attributes][${this.event_testimonial_last_order}][user_name]`
      formGroup2.appendChild(input2)
      var formGroup3 = document.createElement("div")
      formGroup3.classList.add("flex-item")
      formGroup3.classList.add("form-group")
      var input2 = document.createElement("input")
      input2.type = "text"
      input2.placeholder = "Apresentação do Participante"
      input2.name = `event[event_testimonials_attributes][${this.event_testimonial_last_order}][user_position]`
      formGroup3.appendChild(input2)
      var formGroup4 = document.createElement("div")
      formGroup4.classList.add("flex-item")
      formGroup4.classList.add("form-group")
      var textArea = document.createElement("textarea")
      textArea.name = `event[event_testimonials_attributes][${this.event_testimonial_last_order}][user_testimonial]`
      textArea.rows = 1
      textArea.placeholder = "Depoimento"
      formGroup4.appendChild(textArea)
      divFlex2.appendChild(formGroup2)
      divFlex2.appendChild(formGroup3)
      divFlex2.appendChild(formGroup4)
      divItem.appendChild(divFlex2)
      this.eventTestimonialsWrapperTarget.append(divItem)
      this.event_testimonial_last_order++
      this.connect()
    }
  }

  removeEventTestimonial(event) {
    const child = this.eventTestimonialsWrapperTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      this.eventTestimonialsWrapperTarget.lastElementChild.remove()
      this.event_testimonial_last_order--
    }
  }

  removeEventTestimonialAlert(event) {
    if (event.currentTarget.checked)
      alert('Atenção! Você deseja excluir este depoimento?');
  }

  openIconModal(event) {
    this.iconModalTarget.style.display = "block"
    this.clickedEventAdvantageInput = event.currentTarget.id
  }

  chooseIcon(event) {
    event.preventDefault()
    document.querySelector("#" + this.clickedEventAdvantageInput).value = "fa-" + event.currentTarget.dataset.icon
    this.iconModalTarget.style.display = "none"
  }

  removeProductsPageBannerColor() {
    document.querySelector("#products_page_banner_color").style.backgroundColor = 'transparent'
    document.querySelector("#event_products_page_banner_color").value = ''
  }

  removeProductsPageHeaderColor() {
    document.querySelector("#products_page_header_color").style.backgroundColor = 'transparent'
    document.querySelector("#event_products_page_header_color").value = ''
  }
}
