// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = []

  initialize() {
  }

  connect() {

  }

  startZipDownload(event) {
    var r = confirm(`Confirmar o download dos palestrantes?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/listas-de-presenca/exportar.csv?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          name_or_description_i_cont: document.querySelector("#q_name_or_description_i_cont").disabled ? '' : document.querySelector("#q_name_or_description_i_cont").value,
          event_edition_title_or_event_edition_event_title_i_cont: document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").value,
          user_name_or_user_email_i_cont: document.querySelector("#q_user_name_or_user_email_i_cont").disabled ? '' : document.querySelector("#q_user_name_or_user_email_i_cont").value,
          product_name_i_cont: document.querySelector("#q_product_name_i_cont").disabled ? '' : document.querySelector("#q_product_name_i_cont").value
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }
}