// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = [ "couponable" ]

  initialize() {
    this.choiceConfig = {
        searchResultLimit: 15,
        searchChoices: true,
        position: "bottom",
        removeItems: true,
        removeItemButton: true,
        sorter: function() { return },
        noChoicesText: 'Nenhum item para ser selecionado',
        itemSelectText: "Pressione enter para adicionar",
        loadingText: "Carregando..."
    }    
        
    if ((document.querySelector('#coupon_event_edition_ids')) && (document.querySelector('#coupon_product_ids'))) {
        let self = this
        self.choiceProductIds = new Choices(document.querySelector('#coupon_product_ids'), self.choiceConfig)
        self.choiceEventEditionsIds = new Choices(document.querySelector('#coupon_event_edition_ids'), self.choiceConfig)
        self.choiceEventEditionsIds.passedElement.element.addEventListener(
            'addItem',
            function(event) {  
                self.choiceProductIds.setChoices(function(callback) {
                    return fetch(
                      "/filtrar-produtos/" + event.detail.value
                    )
                    .then(function(response) {
                      return response.json()
                    })
                    .then(function(data) {
                      return data.map(function(product) {                          
                        return { value: product.id, label: product.name }
                      })
                    })
                  })      
            },
            false
        )
        self.choiceEventEditionsIds.passedElement.element.addEventListener(
            'removeItem',
            function(event) {  
                if (self.choiceEventEditionsIds.getValue(true).length < 1){
                    self.clearSelectedOptions()
                }                
                self.getRequest("/produtos/filtro/buscar-por-edicao?event_edition_id=" + event.detail.value).then( productIds => {      
                    productIds.forEach(productId => {                       
                        self.choiceProductIds.removeActiveItemsByValue(productId.toString()) 
                        self.choiceProductIds.removeActiveItemsByValue(productId)
                    })
                })  
            },
            false
        )
    }
          
  }

  connect() {    
    if (document.querySelector("[data-target='coupons.couponable']"))      
        this.loadEventEditions(this.couponableTarget.value)   

    if (document.body.getAttribute("data-action").includes("new"))           
        this.clearSelectedOptions()
    
    if (document.body.getAttribute("data-action").includes("edit")) {   
        let self = this       
        self.choiceProductIds.setChoices(function(callback) {
            let eventEditionIds = self.choiceEventEditionsIds.getValue(true)
            if (eventEditionIds.length > 0){
                return fetch(
                    "/filtrar-produtos/" + eventEditionIds
                )
                .then(function(response) {
                    return response.json()
                })
                .then(function(data) {
                    return data.map(function(product) {                          
                      return { value: product.id, label: product.name }
                    })
                })
            }            
        })  
    }
  }

  async getRequest(url){
    const response = await fetch(url)
    return await response.json()
  }

  changeCouponable() {
    this.loadEventEditions(this.couponableTarget.value)
  }

  loadEventEditions(couponableId){   
    this.choiceEventEditionsIds.clearChoices()
    this.choiceProductIds.clearChoices()     
    this.choiceEventEditionsIds.setChoices(function(callback) {
        return fetch(
          "/edicoes-de-evento/listar-todas?couponable_id=" + couponableId
        )
        .then(function(response) {
          return response.json()
        })
        .then(function(data) {
          return data.map(function(eventEdition) {
            return { value: eventEdition.id, label: eventEdition.title }
          })
        })
    })
  }

  clearSelectedOptions(){    
    this.choiceProductIds.clearStore()
  }

  changeEventEditions(event){

  }

}
