// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
  }

  connect() {
  }

  startZipDownload(event) {
    var r = confirm(`Confirmar o download das visualizações?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/visualizacoes/download.zip?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          user_full_text: document.querySelector("#q_user_full_text").disabled ? '' : document.querySelector("#q_user_full_text").value,
          event_edition_full_text: document.querySelector("#q_event_edition_full_text").disabled ? '' : document.querySelector("#q_event_edition_full_text").value,
          presentation_full_text: document.querySelector("#q_presentation_full_text").disabled ? '' : document.querySelector("#q_presentation_full_text").value,
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }
}
