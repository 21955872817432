// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "userType" ]

  initialize() {

  }

  connect() {

  }

  closeTab(event){
    const EventManagerType = 'EventManager'
    console.log(this.userTypeTarget.dataset.value == EventManagerType)
    if (this.userTypeTarget.dataset.value == EventManagerType){
      alert('Categoria de resumo cadastrada com sucesso. Clique em Fechar (Close) e em seguida, salve e atualize a página das categorias para ver a lista atualizada.')
      close()
    }
  }
}
