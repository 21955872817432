// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = [ ]

  initialize() {
    this.choiceConfig = {
      searchResultLimit: 40,
      searchChoices: true,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function(a, b) {            
        return a.disabled - b.disabled
      },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }    
        
    if (document.querySelector('#event_manager_article_ids')) {        
        this.eventManagerArticleIds = new Choices(document.querySelector('#event_manager_article_ids'), this.choiceConfig)        
    }
          
  }
}