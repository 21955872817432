// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = [ "speakers" ]

  initialize() {
    this.choiceConfig = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }

    if (document.querySelector('#agreement_speaker_id'))
      this.choiceSpeakerId = new Choices(document.querySelector('#agreement_speaker_id'), this.choiceConfig)
  }

  changeEventEdition(event) {
    self = this
    self.choiceSpeakerId.clearChoices()
    if (event.currentTarget.value != "") {
      self.choiceSpeakerId.setChoices(function(callback) {
        return fetch(
            "/filtrar-palestrantes/" + event.currentTarget.value
          )
          .then(function(response) {
            return response.json()
          })
          .then(function(data) {
            return [{ value: '', label: '' }].concat(data.map(function(speaker) {
              return { value: speaker.id, label: speaker.name }
            }))
          })
      })
    }
  }
}
