// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "modalReAuthenticateAdmin" ]
  
  initialize() {
  }

  connect() {
    if (document.body.getAttribute("data-action").includes("dashboard")) {
      // var ctx = document.getElementById('statesChart');
      // var myChart = new Chart(ctx, {
      //   type: "bar",
      //   data: {
      //     labels: ["AC","AL","AP","AM","BA","CE","DF","ES","GO","MA","MG","MS","MT","PA","PB","PE","PI","PR","RJ","RN","RO","RR","RS","SC","SE","SP","TO"],
      //     datasets: [
      //       {
      //         data: [14820,13820,12820,11820,10820,9820,8820,7820,6820,5820,4820,3820,2820,1820,1575,1755,2755,3755,4755,5755,6755,8291,9281,2918,29182,3928,8288],
      //         borderColor: "#1E91D6",
      //         backgroundColor: "#1E91D6",
      //         barThickness: 5
      //       }
      //     ]
      //   },
      //   options: {
      //     aspectRatio: 4,
      //     scales: {
      //       xAxes: [{gridLines: { color: "#F5F5F5" }}],
      //       yAxes: [
      //         {
      //           stacked: true,
      //           gridLines: {
      //             borderDash: [8, 4],
      //             color: "#CBCBCD"
      //           }
      //         }
      //       ]
      //     },
      //     legend: {
      //       display: false,
      //     },
      //   }
      // });

      // var ctx = document.getElementById('countriesChart');
      // var myChart = new Chart(ctx, {
      //   type: "bar",
      //   data: {
      //     labels: ["Brasil","Estados Unidos","França","Canadá","Portugal","China","Hungria","Itália"],
      //     datasets: [
      //       {
      //         data: [14820,13820,12820,11820,10820,9900,9300,8700],
      //         borderColor: "#1E91D6",
      //         backgroundColor: "#1E91D6",
      //         barThickness: 15
      //       }
      //     ]
      //   },
      //   options: {
      //     aspectRatio: 4,
      //     scales: {
      //       xAxes: [{gridLines: { color: "#F5F5F5" }}],
      //       yAxes: [
      //         {
      //           stacked: true,
      //           gridLines: {
      //             borderDash: [8, 4],
      //             color: "#CBCBCD"
      //           }
      //         }
      //       ]
      //     },
      //     legend: {
      //       display: false,
      //     },
      //   }
      // });
    }
  }

  openReAuthenticateAdminModal(event){    
    this.modalReAuthenticateAdminTarget.style.display = "block"
    this.modalEventManagerDeleteConfirmation = event.target.parentNode.nextElementSibling
  }

  reAuthenticateAdmin(event) {     
    this.modalReAuthenticateAdminTarget.style.display = "none"  
    this.modalEventManagerDeleteConfirmation.style.display = "block"    
  }
}
