// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "fullCertificate", "subscriptions", "speakersCertificatesEnabled", "speakersCertificatesConfigurations", "generateSpeakersCertificatesBtn", "modalGenerateSpeakersCertificates", "previewModalIframe", "formPreviewSpeakerCertificates", "saveBtn" ]

  initialize() {    

  }

  connect() {    
    this.speakersCertificatesEnabled()
    this.changeFullCertificateTemplate()    
  }

  speakersCertificatesEnabled() {
    if (this.speakersCertificatesEnabledTarget.checked) {
      this.speakersCertificatesConfigurationsTarget.classList.remove("is-display-none")
      this.formPreviewSpeakerCertificatesTarget.classList.remove("is-display-none")
      this.saveBtnTarget.value = "SALVAR E PRÉ-VISUALIZAR CERTIFICADO"
    } else {
      this.speakersCertificatesConfigurationsTarget.classList.add("is-display-none")
      this.formPreviewSpeakerCertificatesTarget.classList.add("is-display-none")
      this.saveBtnTarget.value = "SALVAR"
    }        
  }

  changeFullCertificateTemplate(event) {
    if (this.fullCertificateTarget.checked) {
      this.subscriptionsTarget.classList.add("is-display-none")
    } else {
      this.subscriptionsTarget.classList.remove("is-display-none")
    }
  }  

  generateSpeakersCertificates(event){
    self = this
    var r = confirm(`Tem certeza que deseja emitir e enviar os certificados aos palestrantes?`)
    if (r == true) {           
      fetch("/edicoes-de-evento/" + this.generateSpeakersCertificatesBtnTarget.dataset.eventeditionid + "/enviar-certificados-de-palestrantes", {
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content, 
          "Content-Type": "application/json"}, 
          method: "POST"
      })
      .then(function(res){         
        return res.json()
      })
      .then(function(data){   
        self.modalGenerateSpeakersCertificatesTarget.style.display = "none"      
        alert(data.message)
        window.location = '/certificados-de-palestrantes'
      })
    }
  }

  generateSpeakersCertificatesModal(event){    
    this.modalGenerateSpeakersCertificatesTarget.style.display = "block"    
  }

  goToSaveBtn() {
    this.saveBtnTarget.scrollIntoView({behavior: "smooth", block: "center"})
  }
}