// JS Controller for global methods and functions

import { Controller } from "stimulus"
import AWS from 'aws-sdk'
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = [ "formErrors", "videoWrapper", "complementaryMaterialWrapper", "specificErrorWapper", "loadingModal", "complementaryMaterialLoading", "eventManagerObservationToWrapper", "eventManagerObservationFromWrapper", "speakerObservationToWrapper", "uploadMaterialButton", "presentationId" ]

  connect() {
    let bucketName = "videos-and-complementary-materials"
    let bucketRegion = "us-east-1"
    let IdentityPoolId = "us-east-1:069a3aa5-c7f3-437c-8dc9-b588b994e75c"
    this.archives = []
    this.videoItemCount = document.querySelectorAll(".video-wrapper-item").length
    this.complementaryMaterialItemCount = 0
    this.specificErrorItemCount = 0

    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    })

    this.s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {
        Bucket: bucketName
      }
    })

    if (document.querySelector(".complementary-material-file") && !document.querySelector('#presentation_material_video_title'))
      this.addComplementaryMaterial()      
  }

  addFormErrors(error) {
    this.formErrorsTarget.innerText = ""
    for (var i = 0; i < error.length; i++) {
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error[i]))
      this.formErrorsTarget.appendChild(li)
    }
  }

  processSpeakerArchives(event) {
    this.archives = []
    this.specific_errors = []    
    this.event_slug = event.target.dataset.eventSlug
    this.speaker_name = event.target.dataset.speakerName
    this.url = event.target.dataset.url
    this.notificationUrl = event.target.dataset.notificationUrl
    this.hasVideoFiles = event.target.dataset.hasVideoFiles == '1'
    this.persistedMaterials = []
    this.persistedErrors = []

    let errors = []
    let video_url_inputs = document.querySelectorAll('.video-url')
    let complementary_material_url_inputs = document.querySelectorAll('.complementary-material-type')
    let specific_error_url_inputs = document.querySelectorAll('.specific-error-name')
    let at_least_one_video = false
    let self = this

    if (!this.hasVideoFiles && video_url_inputs.length == 0) {
      errors.push("O arquivo de video deve ser selecionado")
    } else {
      video_url_inputs.forEach(function(video_url_input){
        if (video_url_input.files.length && self.checkVideoType(video_url_input.files) == false) {
          errors.push("Somente arquivos de vídeo podem ser selecionados no campo 'Video da palestra'")
        } else if (!video_url_input.files.length && !at_least_one_video) {
          errors.push("O arquivo de video deve ser selecionado")
          at_least_one_video = true
        } else {
          at_least_one_video = true
        }
      })
    }

    complementary_material_url_inputs.forEach(function(item, index){
      if (document.querySelectorAll(".complementary-material-file")[index].files.length == 0 && !item.value && document.querySelectorAll(".complementary-material-start")[index].value == '00:00:00' && document.querySelectorAll(".complementary-material-end")[index].value == '00:00:00')
        return

      if (document.querySelectorAll(".complementary-material-file")[index].files.length == 0) {
        errors.push(`O ${index + 1}º arquivo de Materiais Complementares está vazio. Selecione o arquivo.`)
      }
      if (!item.value){
        errors.push(`Selecione a Utilização do Material do ${index + 1}º Material Complementar.`)
      }
      if (item.value == 'use_on_presentation' && document.querySelectorAll(".complementary-material-start")[index].value == '00:00:00') {
        errors.push(`O Início do minuto para inserção do ${index + 1}º arquivo de Materiais Complementar está vazio.`)
      }
      if (item.value == 'use_on_presentation' && document.querySelectorAll(".complementary-material-end")[index].value == '00:00:00') {
        errors.push(`O Fim do minuto para inserção do ${index + 1}º arquivo de Materiais Complementar está vazio.`)
      }
    })

    specific_error_url_inputs.forEach(function(item, index){
      if (!item.value && document.querySelectorAll(".specific-error-start")[index].value == '00:00:00' && document.querySelectorAll(".specific-error-end")[index].value == '00:00:00')
        return

      if (!item.value){
        errors.push(`Selecione a Nome do Arquivo do ${index + 1}º Erro Específico.`)
      }
      if (document.querySelectorAll(".specific-error-start")[index].value == '00:00:00') {
        errors.push(`O Início do minuto para corte do ${index + 1}º Erro Específico está vazio.`)
      }
      if (document.querySelectorAll(".specific-error-end")[index].value == '00:00:00') {
        errors.push(`O Fim do minuto para corte do ${index + 1}º Erro Específico está vazio.`)
      }
    })

    if (this.hasVideoFiles && video_url_inputs.length == 0 && complementary_material_url_inputs.length == 0 && specific_error_url_inputs.length == 0)
      errors.push(`Você deve especificar ao menos um vídeo, um material complementar ou um erro específico.`)

    if (errors.length > 0){
      this.addFormErrors(errors)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      var r = confirm(`Tem certeza que deseja finalizar? Você não poderá alterar as informações ou acrescentar novos vídeos a este envio.`)
      if (r == true) {
        video_url_inputs.forEach(function(video_url_input){
          if (video_url_input.files.length) {
            self.archives.push({
              "kind": "video",
              // "edition_needs": document.querySelector("#presentation_material_edition_needs").checked,
              "video_language": document.querySelector("#presentation_material_video_language").value,
              "video_file": video_url_input.files,
              "video_url": null
            })
          }
        })

        document.querySelectorAll(".complementary-material-file").forEach(function(item, index) {
          if (item.files.length) {
            self.archives.push({
              "kind": "complementary_material",
              "complementary_material_description": document.querySelectorAll(".complementary-material-description")[index].value || null,
              "complementary_material_type": document.querySelectorAll(".complementary-material-type")[index].value || null,
              "complementary_material_start": document.querySelectorAll(".complementary-material-start")[index].value || null,
              "complementary_material_end": document.querySelectorAll(".complementary-material-end")[index].value || null,
              "complementary_material_file": item.files,
              "complementary_material_url": null
            })
          }
        })

        document.querySelectorAll(".specific-error-name").forEach(function(item, index) {
          if (document.querySelectorAll(".specific-error-name")[index].value) {
            self.specific_errors.push({
              "file_name": document.querySelectorAll(".specific-error-name")[index].value || null,
              "cut_start": document.querySelectorAll(".specific-error-start")[index].value || null,
              "cut_end": document.querySelectorAll(".specific-error-end")[index].value || null
            })
          }
        })

        if (self.archives.length == 0 && self.specific_errors.length == 0) {
          errors.push(`Você deve especificar ao menos um vídeo, um material complementar ou um erro específico.`)
          this.addFormErrors(errors)
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }

        this.loadingModalTarget.style.display = "block"
        self.archives.forEach(function(value, index) {
          var files = null
          self.archives[index].kind == "video" ? files = self.archives[index].video_file : files = self.archives[index].complementary_material_file
          var file = files[0]          
          var fileName = self.addPresentationIdToFileName(file.name.trim().replace(/\s/g, "_").replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '_'))          
          self.complementaryMaterialLoadingTarget.innerHTML += `<div class="progress" id="file-name-${index}">
            <progress value="0" max="100"></progress>
            <span class="label">${fileName}: <span id="progress-bar-${index}">0%</span></span>
          </div>`
        })

        fetch('/conteudos/' + self.presentationIdTarget.value, {
          method: 'PUT',
          headers: {
            "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
            'Accept': 'application/json',
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            presentation: {
              validate_form: false,
              presentation_specific_errors_attributes: self.specific_errors
            }
          })
        })
        .then(function(response) { 
          response.json().then(r => {
            if (response.ok) {
              self.persistedErrors = r.last_added_errors
              self.upload(0, self.archives[0])
            }
          })
        })
      }
    }
  }

  upload(index, archive){
    let self = this
    window.onbeforeunload = this.confirmExit
    if (index < self.archives.length){
      var files = null
      archive.kind == "video" ? files = archive.video_file : files = archive.complementary_material_file
      var file = files[0]
      var fileName = self.addPresentationIdToFileName(file.name.trim().replace(/\s/g, "_").replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '_'))
      console.log(fileName)
      var filePath = this.event_slug + "/" + this.speaker_name.trim().replace(/\s/g, "_") + "/" + fileName
      console.log(filePath)
      this.s3.upload({
        Key: filePath,
        Body: file,
        ACL: 'public-read'
      }, function(err, data) {
        if (err) {
          console.log(err)
        } else {
          if (data.Location != "" || data.Location != undefined){
            archive.kind == "video" ? archive.video_url = data.Location : archive.complementary_material_url = data.Location
            archive.kind == "video" ? delete archive.video_file : delete archive.complementary_material_file
            archive.file_name = fileName
            fetch(self.url, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(archive)
            })
            .then(function(response) { 
              response.json().then(r => {
                if (response.ok) {
                  index++
                  self.persistedMaterials.push(r.id)
                  self.upload(index, self.archives[index])
                }
              })
            })
          }
        }
      }).on('httpUploadProgress', function (progress) {
        var uploaded = parseInt((progress.loaded * 100) / progress.total)
        document.querySelector(`#file-name-${index} progress`).value = uploaded
        document.querySelector(`#progress-bar-${index}`).innerHTML = uploaded + '%'
      })
    } else {
      fetch(self.notificationUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          presentation_id: self.presentationIdTarget.value,
          persisted_materials_ids: self.persistedMaterials,
          persisted_errors_ids: self.persistedErrors
        })
      })
      .then(function(response) { return response.json() })
      .then(function(r){
        window.onbeforeunload = null
        window.location.replace(r.redirect_to)
      })
    }
  }

  checkVideoType(file) {
    if (file[0].type.match('video.*')){
      return true
    } else {
      return false
    }
  }

  confirmExit() {
    return "Não feche esta janela. Seus arquivos estão sendo enviados..."
  }

  addVideo(){
    this.videoWrapperTarget.insertAdjacentHTML('beforeend', `<div class="video-wrapper-item">
      <div class="flex">
        <div class="flex-item form-group">
          <input type="file" name="video_url_${this.videoItemCount}" class="is-display-block video-url" data-action="change->presentation-materials#changeVideoUrl">
        </div>
      </div>
    </div>`)
    this.videoItemCount++
  }

  removeVideo(){
    if (this.videoWrapperTarget.lastElementChild) {
      this.videoWrapperTarget.lastElementChild.remove()
      this.videoItemCount--
    }
  }

  changeVideoUrl(event){
    let self = this
    let fileInputs = document.querySelectorAll('.video-url')
    let selects = document.querySelectorAll('.specific-error-name')
    let filePath = this.videoWrapperTarget.dataset.eventSlug + "/" + this.videoWrapperTarget.dataset.speakerName.trim().replace(/\s/g, "_") + "/"    
    let fileName = self.addPresentationIdToFileName(event.currentTarget.files[0].name.trim().replace(/\s/g, "_").replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '_'))
    console.log(fileName)
    let inputName = event.currentTarget.name
    this.uploadMaterialButtonTarget.disabled = true

    this.s3.listObjects({
      Delimiter: '/',
      Prefix: filePath
    }, function(err, data) {
      if (err) {
        console.log(err)
      } else {
        let hasName = false
        
        if (Array.from(fileInputs).some((item) => inputName != item.name && self.addPresentationIdToFileName(item.files[0].name) == fileName))
          hasName = true

        data.Contents.forEach(function(content){
          let key = content.Key.split('/').pop()
          if (fileName == key)
            hasName = true
        })

        if (hasName) {
          document.querySelector(`input[type=file][name=${inputName}]`).value = ""
          window.scrollTo({ top: 0, behavior: 'smooth' })
          alert('Já existe um arquivo com este nome. Por favor, altere o nome do arquivo e tente novamente.')
        } else {
          selects.forEach(function(select){
            select.innerHTML = ""
            let option = document.createElement("option")
            option.text = "Selecione"
            option.value = ""
            select.appendChild(option)
            fileInputs.forEach(function(item){
              let filename = self.addPresentationIdToFileName(item.files[0].name)
              console.log(filename)
              let option = document.createElement("option")
              option.text = filename
              option.value = filename
              select.appendChild(option)
            })
          })
          self.uploadMaterialButtonTarget.disabled = false
        }
      }
    })
  }

  initializeDatePicker(input_name) {
    let flatpickrConfig = {
      enableTime: true,
      noCalendar: true,
      enableSeconds: true,
      dateFormat: "H:i:S",
      defaultDate: "00:00:00",
      time_24hr: true
    }
    flatpickr(input_name, flatpickrConfig)
  }

  addComplementaryMaterial(){
    this.complementaryMaterialWrapperTarget.insertAdjacentHTML('beforeend', `<div class="complementary-material-wrapper-item">
      <div class="flex">
        <div class="flex-item form-group">
          <label for="complementary_material_url">
            Arquivo
            <span>&nbsp;</span>
          </label>
          <input type="file" name="complementary_material_url_${this.complementaryMaterialItemCount}" class="is-display-block complementary-material-file">
        </div>
        <div class="flex-item form-group">
          <label for="complementary_material_type">
            Utilização do Material
            <span>&nbsp;</span>
          </label>
          <select name="complementary_material_type_${this.complementaryMaterialItemCount}" class="is-display-block complementary-material-type" data-action="change->presentation-materials#changeMaterialType" data-index="${this.complementaryMaterialItemCount}">
            <option></option>
            <option value="use_on_presentation">Usar na edição da minha palestra</option>
            <option value="share_link">Disponibilizar para os congressistas</option>
            <option value="zoom_backup">Arquivos de backup do zoom</option>
          </select>
        </div>
        <div class="flex-item form-group">
          <label for="complementary_material_description">
            Comentário
            <span>Ex.: Mostrar slide 32 do material</span>
          </label>
          <input type="text" name="complementary_material_description_${this.complementaryMaterialItemCount}" class="is-display-block complementary-material-description">
        </div>
        <div class="flex-item form-group">
          <span>
            <strong>Minuto do vídeo para inserção</strong>
          </span>
          <div class="flex">
            <div class="flex-item form-group">
              <label for="complementary_material_start">
                <span>Início</span>
              </label>
              <input type="text" name="complementary_material_start_${this.complementaryMaterialItemCount}" class="is-display-block complementary-material-start datetime-time-select-input">
            </div>
            <div class="flex-item form-group">
              <label for="complementary_material_end">
                <span>Fim</span>
              </label>
              <input type="text" name="complementary_material_end_${this.complementaryMaterialItemCount}" class="is-display-block complementary-material-end datetime-time-select-input">
            </div>
          </div>
        </div>
      </div>
    </div>`)
    this.initializeDatePicker(`input[name=complementary_material_start_${this.complementaryMaterialItemCount}]`)
    this.initializeDatePicker(`input[name=complementary_material_end_${this.complementaryMaterialItemCount}]`)
    this.complementaryMaterialItemCount++
  }

  removeComplementaryMaterial(){
    if (this.complementaryMaterialWrapperTarget.lastElementChild) {
      this.complementaryMaterialWrapperTarget.lastElementChild.remove()
      this.complementaryMaterialItemCount--
    }
  }

  changeMaterialType(event){
    let index = event.target.dataset.index    
    if (event.target.value == 'share_link' || event.target.value == 'zoom_backup') {
      document.querySelectorAll(".complementary-material-description")[index].disabled = true
      document.querySelectorAll(".complementary-material-start")[index].disabled = true
      document.querySelectorAll(".complementary-material-end")[index].disabled = true
    } else {
      document.querySelectorAll(".complementary-material-description")[index].disabled = false
      document.querySelectorAll(".complementary-material-start")[index].disabled = false
      document.querySelectorAll(".complementary-material-end")[index].disabled = false
    }
  }

  addSpecificError(event){
    this.specificErrorWapperTarget.insertAdjacentHTML('beforeend', `<div class="specific-error-wrapper-item">
      <div class="flex">
        <div class="flex-item form-group">
          <label for="specific_error_name">
            Nome do Arquivo
            <span>Nome do arquivo enviado no topo deste formulário</span>
          </label>
          <select name="specific_error_name_${this.specificErrorItemCount}" class="is-display-block specific-error-name">
          </select>
        </div>
        <div class="flex-item form-group">
          <span>
            <strong>Minuto do vídeo para corte</strong>
          </span>
          <div class="flex">
            <div class="flex-item form-group">
              <label for="specific_error_start">
                <span>Início</span>
              </label>
              <input type="text" name="specific_error_start_${this.specificErrorItemCount}" class="is-display-block specific-error-start datetime-time-select-input">
            </div>
            <div class="flex-item form-group">
              <label for="specific_error_end">
                <span>Fim</span>
              </label>
              <input type="text" name="specific_error_end_${this.specificErrorItemCount}" class="is-display-block specific-error-end datetime-time-select-input">
            </div>
          </div>
        </div>
      </div>
    </div>`)

    let fileInputs = document.querySelectorAll('.video-url')
    let select = document.querySelector(`select[name=specific_error_name_${this.specificErrorItemCount}]`)
    let option = document.createElement("option")
    let errorNames = event.currentTarget.dataset.filesNames.split(',')

    option.text = "Selecione"
    option.value = ""
    select.appendChild(option)
    var self = this
    fileInputs.forEach(function(item){
      if (item.files.length) {
        let filename = self.addPresentationIdToFileName(item.files[0].name)
        console.log(filename)
        let option = document.createElement("option")
        option.text = filename
        option.value = filename
        select.appendChild(option)
      }
    })
    errorNames.forEach(function(errorName){
      if (errorName) {
        let filename = errorName
        let option = document.createElement("option")
        option.text = filename
        option.value = filename
        select.appendChild(option)
      }
    })
    this.initializeDatePicker(`input[name=specific_error_start_${this.specificErrorItemCount}]`)
    this.initializeDatePicker(`input[name=specific_error_end_${this.specificErrorItemCount}]`)
    this.specificErrorItemCount++
  }

  removeSpecificError(){
    if (this.specificErrorWapperTarget.lastElementChild) {
      this.specificErrorWapperTarget.lastElementChild.remove()
      this.specificErrorItemCount--
    }
  }

  addEventManagerObservationTo(){
    this.eventManagerObservationToWrapperTarget.insertAdjacentHTML('beforeend', `<div class="event-manager-observation-to-wrapper-item">
      <div class="flex">
        <input type="hidden" name="presentation[presentation_observations_attributes][][observation_type]" value="to_event_manager">
        <div class="flex-item form-group">
          <label for="complementary_material_description">
            Comentário
          </label>
          <textarea type="text" name="presentation[presentation_observations_attributes][][observation]" class="is-display-block" rows="1"></textarea>
        </div>
        <div class="flex-item form-group">
          <label for="complementary_material_url">
            Arquivo
          </label>
          <input type="file" name="presentation[presentation_observations_attributes][][observation_file]" class="is-display-block">
        </div>
        <div class="flex-item form-group"></div>
      </div>
    </div>`)
  }

  removeEventManagerObservationTo(){
    if (this.eventManagerObservationToWrapperTarget.lastElementChild) {
      this.eventManagerObservationToWrapperTarget.lastElementChild.remove()
    }
  }

  addEventManagerObservationFrom(){
    this.eventManagerObservationFromWrapperTarget.insertAdjacentHTML('beforeend', `<div class="event-manager-observation-from-wrapper-item">
      <div class="flex">
        <input type="hidden" name="presentation[presentation_observations_attributes][][observation_type]" value="from_event_manager">
        <div class="flex-item form-group">
          <label for="complementary_material_description">
            Comentário
          </label>
          <textarea type="text" name="presentation[presentation_observations_attributes][][observation]" class="is-display-block presentation-observations-observation" rows="1"></textarea>
        </div>
        <div class="flex-item form-group">
          <label for="complementary_material_url">
            Arquivo
          </label>
          <input type="file" name="presentation[presentation_observations_attributes][][observation_file]" class="is-display-block">
        </div>
        <div class="flex-item form-group"></div>
      </div>
    </div>`)
  }

  removeEventManagerObservationFrom(){
    if (this.eventManagerObservationFromWrapperTarget.lastElementChild) {
      this.eventManagerObservationFromWrapperTarget.lastElementChild.remove()
    }
  }

  addSpeakerObservationTo(){
    this.speakerObservationToWrapperTarget.insertAdjacentHTML('beforeend', `<div class="speaker-observation-to-wrapper-item">
      <div class="flex">
        <input type="hidden" name="presentation[presentation_observations_attributes][][observation_type]" value="to_speaker">
        <div class="flex-item form-group">
          <label for="complementary_material_description">
            Comentário
          </label>
          <textarea type="text" name="presentation[presentation_observations_attributes][][observation]" class="is-display-block" rows="1"></textarea>
        </div>
        <div class="flex-item form-group">
          <label for="complementary_material_url">
            Arquivo
          </label>
          <input type="file" name="presentation[presentation_observations_attributes][][observation_file]" class="is-display-block">
        </div>
        <div class="flex-item form-group"></div>
      </div>
    </div>`)
  }

  removeSpeakerObservationTo(){
    if (this.speakerObservationToWrapperTarget.lastElementChild) {
      this.speakerObservationToWrapperTarget.lastElementChild.remove()
    }
  }

  validateStatusPresentationForm(event){
    let errors = []

    document.querySelectorAll(".presentation-observations-observation").forEach(function(item, index) {
      if (!document.querySelectorAll(".presentation-observations-observation")[index].value)
        errors.push('Você deve especificar um comentário')
    })

    if (errors.length > 0){
      event.preventDefault()
      this.addFormErrors(errors)
      document.querySelector("#update_status_presentation_materials_form").scrollIntoView({behavior: "smooth", block: "center"})
      setTimeout(function(){
        window.scrollBy(0, -20)
      }, 100)
    } else {
      if (event.target.dataset.confirmMessage){
        var r = confirm(event.target.dataset.confirmMessage)
        if (r == false)
          event.preventDefault()
      }
    }
  }

  addPresentationIdToFileName(originalFile) {      
    let splitedOriginalFileName = originalFile.split(".")
    return splitedOriginalFileName.slice(0, -1).join("_") + "_" + this.presentationIdTarget.value + "." + splitedOriginalFileName.pop()
  }
}
