// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = [ "products", "iframe" ]

  initialize() {
  }

  connect() {
    if (document.querySelector("#certificate_certificable_gid")) {
      this.loadCertificables()
    }
  }

  changeEventEdition(event) {
    var productsTarget = this.productsTarget
    var products = []
    if (event.currentTarget.value == "") {
      productsTarget.options.length = 1
    } else {
      for (var i = 0; i < event.currentTarget.options.length; i++) {
        products.push(event.currentTarget.options[i].value)
      }

      fetch("/filtrar-produtos/" + products).then(
        function(response) {
          return response.json().then(function(json) {
            productsTarget.options.length = 1
            for (var i = 0; i < json.length; i++) {
              var option = document.createElement("option")
              option.appendChild(document.createTextNode(json[i]["name"]))
              option.value = json[i]["id"]
              productsTarget.appendChild(option)
            }
          })
        }
      )
    }
  }

  updatePDFFrame(event) {
    let messages = document.getElementById("messages")    
    let p = document.createElement("p")
    p.classList.add("success")
    p.appendChild(document.createTextNode("Certificado editado com sucesso"))
    messages.appendChild(p)
    this.iframeTarget.contentDocument.location.reload(true);
  }

  loadCertificables() {
    let config = {
      searchResultLimit: 15,
      searchChoices: false,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }
    let select = document.querySelector('#certificate_certificable_gid')
    let choice = new Choices(select, config)
    let myTimeOut = null
    select.addEventListener('search', function(event) {
      clearTimeout(myTimeOut)
      choice.clearChoices()
      myTimeOut = setTimeout(() => { 
        if (event.detail.value) {
          choice.setChoices(function(callback) {
            return fetch(
              '/certificados/usuarios?q=' + event.detail.value
            )
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              return data.results.map(function(user) {
                return { value: user.id, label: user.name }
              })
            })
          })
        }
      }, 1000)
    })

    select.addEventListener('showDropdown', function(event){
      let choicesList = event.target.parentNode.nextSibling.querySelector(".choices__list")
      let input = event.target.parentNode.nextSibling.querySelector("input")
      choicesList.addEventListener('DOMNodeInserted', function(){
        setTimeout(() => {
          input.focus()
        }, 1)
      })
    })
  }
}
