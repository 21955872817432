// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "commentFormErrors", "iframe", "iframeResended", "requestCorrections", "correctionsCommentsDiv", "correctionsComments", "authors"]

  initialize() {
  }

  connect() {
    if (this.hasIframeTarget && this.iframeTarget.dataset.articleUrl) {
      let encodedUrl = encodeURIComponent(this.iframeTarget.dataset.articleUrl)
      let iFrameUrl = 'https://docs.google.com/viewer?url=' + encodedUrl + '&embedded=true'
      this.iframeTarget.setAttribute("src", iFrameUrl)
    }

    if (this.hasIframeResended && this.iframeResended.dataset.articleUrl) {
      let encodedResendedUrl = encodeURIComponent(this.iframeResended.dataset.articleUrl)
      let iFrameResendedUrl = 'https://docs.google.com/viewer?url=' + encodedResendedUrl + '&embedded=true'
      this.iframeResended.setAttribute("src", iFrameResendedUrl)
    }
    if (this.hasRequestCorrectionsTarget){
      this.changeRequestCorrections()
    }
  }

  updatePDFFrame() {
    this.iframeTarget.contentDocument.location.reload(true);
    if (this.hasIframeResended){
      this.iframeResended.contentDocument.location.reload(true);
    }
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail
    let element = document.querySelector("div.messages")
    element.classList.remove("is-display-none")
    document.querySelector("div.messages p").textContent = JSON.parse(event.detail[2].response).message
    document.querySelector('#article-error-messages').innerHTML = ''
  }

  // Error form
  onCommentPostError(event) {
    this.commentFormErrorsTarget.innerText = ""
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error))
      this.commentFormErrorsTarget.appendChild(li)
    }
  }

  startZipDownloadAll(event) {
    var r = confirm(`Confirmar o download de todos os resumos?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/resumos/download_all.zip?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          title_or_names_or_emails_i_cont: document.querySelector("#q_title_or_names_or_emails_i_cont").disabled ? '' : document.querySelector("#q_title_or_names_or_emails_i_cont").value,
          event_edition_title_or_event_edition_event_title_i_cont: document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").value,
          status_eq: document.querySelector("#q_status_eq").disabled ? '' : document.querySelector("#q_status_eq").value
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }

  startZipDownloadPaids(event) {
    var r = confirm(`Confirmar o download dos resumos promovidos para os anais?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/resumos/download_paids.zip?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          title_or_names_or_emails_i_cont: document.querySelector("#q_title_or_names_or_emails_i_cont").disabled ? '' : document.querySelector("#q_title_or_names_or_emails_i_cont").value,
          event_edition_title_or_event_edition_event_title_i_cont: document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").value,
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }

  notifyApprovedResumesAuthors() {
    var r = confirm(`Confirmar envio das notificações para os autores dos resumos aprovados desta edição?`)
    if (r == true) {
      fetch("/edicoes-de-evento/notificacao-de-autores", {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para todos os autores cujos resumos estejam aprovados.')
      })
    }
  }

  startEvaluationsDowloadAll(){
    var r = confirm(`Confirmar o download das avaliações dos resumos?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/resumos/download_evaluations.zip?" + new URLSearchParams({
        q: JSON.stringify({
          created_at_gteq: document.querySelector("#q_created_at_gteq").value,
          created_at_lteq: document.querySelector("#q_created_at_lteq").value,
          title_or_names_or_emails_i_cont: document.querySelector("#q_title_or_names_or_emails_i_cont").disabled ? '' : document.querySelector("#q_title_or_names_or_emails_i_cont").value,
          event_edition_title_or_event_edition_event_title_i_cont: document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").disabled ? '' : document.querySelector("#q_event_edition_title_or_event_edition_event_title_i_cont").value
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }

  changeRequestCorrections(){
    if (this.requestCorrectionsTarget.value == 'true'){
      this.correctionsCommentsDivTarget.classList.remove("is-display-none")
      this.loadCKEditor()
    } else {
      this.correctionsCommentsDivTarget.classList.add("is-display-none")
    }
  }

  loadCKEditor() {
    let toolbarConfig = [
      { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
      { name: 'styles', items: [ 'Font', 'FontSize' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'CopyFormatting' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' ] },
      { name: 'insert', items: ['base64image', 'Table'] },
      { name: 'editing', items: [ 'Scayt' ] }
    ]

    let config = {
      customConfig: '/js/ckeditor_config.js',
      toolbar: toolbarConfig,
      wordcount: {
        showWordCount: true,
        showCharCount: false
      }
    }

    if (CKEDITOR.instances.hasOwnProperty("article_corrections_comments")) {
      CKEDITOR.instances.article_corrections_comments.destroy()
    }
    CKEDITOR.replace(document.querySelector('#article_corrections_comments'), config)
  }

  handleFileSelect(evt) {
    self = this

    let tgt = evt.target || window.event.srcElement
    let files = tgt.files
    if (FileReader && files && files.length) {
      var fr = new FileReader()
      fr.onload = function () {
        self.base64FileValue = fr.result
      }
      fr.readAsDataURL(files[0])
    }
  }

  addAuthor(event) {
    if (!(this.countAuthorsFields() >= 22)){
      var divItem = document.createElement("div")
      divItem.classList.add("item")
      var divFlex1 = document.createElement("div")
      divFlex1.classList.add("flex")
      var formGroup1 = document.createElement("div")
      formGroup1.classList.add("flex-item")
      formGroup1.classList.add("form-group")
      var input1 = document.createElement("input")
      input1.type = "text"
      input1.name = "article[names][]"
      input1.placeholder = (this.countAuthorsFields()) + "º Autor (nome completo)"
      formGroup1.appendChild(input1)
      divFlex1.appendChild(formGroup1)
      var divFlex2 = document.createElement("div")
      divFlex2.classList.add("flex")
      var formGroup2 = document.createElement("div")
      formGroup2.classList.add("flex-item")
      formGroup2.classList.add("form-group")
      var input2 = document.createElement("input")
      input2.type = "text"
      input2.placeholder = (this.countAuthorsFields()) + "º Instituição"
      input2.name = "article[institutions][]"
      formGroup2.appendChild(input2)
      var formGroup3 = document.createElement("div")
      formGroup3.classList.add("flex-item")
      formGroup3.classList.add("form-group")
      var input3 = document.createElement("input")
      input3.name = "article[emails][]"
      input3.type = "email"
      input3.placeholder = (this.countAuthorsFields()) + "º Email"
      formGroup3.appendChild(input3)      
      var formGroup4 = document.createElement("div")
      formGroup4.classList.add("flex-item")
      formGroup4.classList.add("form-group")
      var input4 = document.createElement("input")
      input4.name = "article[whatsapp_numbers][]"
      input4.type = "text"
      input4.placeholder = (this.countAuthorsFields()) + "º Whatsapp"
      formGroup4.appendChild(input4)
      divFlex2.appendChild(formGroup2)
      divFlex2.appendChild(formGroup3)
      divFlex2.appendChild(formGroup4)
      divItem.appendChild(divFlex1)
      divItem.appendChild(divFlex2)
      this.authorsTarget.append(divItem)
    }
  }

  createInput(attribute, type = "text") {
    const input = document.createElement("input");
    input.name = `article[${attribute}][]`;
    input.type = type;
    input.placeholder = `${this.countAuthorsFields()}º ${this.capitalizeFirstLetter(attribute)}`;
    return input;
  }

  countAuthorsFields() {
    return this.authorsTarget.querySelectorAll(".flex").length;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  removeAuthor(event) {
    const child = this.authorsTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      this.authorsTarget.lastElementChild.remove()
    }
  }
}
