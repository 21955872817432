// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["userLevel", "listUsers", "modalReAuthenticateAdmin", "modalDeleteUser"]

  initialize() {}

  connect() {
    if (document.body.getAttribute("data-action").includes("new") || document.body.getAttribute("data-action").includes("edit")) {
      if (!this.userLevelTarget.checked)
        document.getElementById("products").classList.add("is-display-none")
    }
  }

  showProducts(event) {
    if (event.currentTarget.checked == true) {
      document.getElementById("products").classList.remove("is-display-none")
    } else {
      document.getElementById("products").classList.add("is-display-none")
    }
  }

  startZipDownload(event) {
    var r = confirm(`Confirmar o download dos usuários?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/usuarios/download.zip?" + new URLSearchParams({
          q: JSON.stringify({
            created_at_gteq: document.querySelector("#q_created_at_gteq").value,
            created_at_lteq: document.querySelector("#q_created_at_lteq").value,
            name_or_email_or_phone_number_i_cont: document.querySelector("#q_name_or_email_or_phone_number_i_cont").disabled ? '' : document.querySelector("#q_name_or_email_or_phone_number_i_cont").value,
          })
        }))
        .then(res => {
          return res.text()
        }).then(data => {
          alert('Um email será enviado para você com o link para download.')
          event.target.style.pointerEvents = null
        })
    }
  }

  startLeadsZipDownload(event) {
    var r = confirm(`Confirmar o download dos leads para contato de vendas?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/usuarios/download-leads.zip?" + new URLSearchParams({
          q: JSON.stringify({
            user_created_at_gteq: document.querySelector("#q_created_at_gteq").value,
            user_created_at_lteq: document.querySelector("#q_created_at_lteq").value,
            user_name_or_user_email_or_user_phone_number_i_cont: document.querySelector("#q_name_or_email_or_phone_number_i_cont").disabled ? '' : document.querySelector("#q_name_or_email_or_phone_number_i_cont").value,
          })
        }))
        .then(res => {
          return res.text()
        }).then(data => {
          alert('Um email será enviado para você com o link para download.')
          event.target.style.pointerEvents = null
        })
    }
  }

  openReAuthenticateAdminModal(event) {
    this.modalReAuthenticateAdminTarget.style.display = "block"
    this.modalUserDeleteConfirmation = event.target.parentNode.nextElementSibling
  }

  reAuthenticateAdmin(event) {
    this.modalReAuthenticateAdminTarget.style.display = "none"
    this.modalUserDeleteConfirmation.style.display = "block"
  }
}